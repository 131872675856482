import React, { Fragment, useState } from "react";
import { Col, Form, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { ibService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput } from 'CommonElements/Form';
import { Btn, H3, H4 } from "AbstractElements";

const RejectIb = ({ customerId, loadData }) => {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const toggle = () => {
        setModal(!modal)

    };
    const { register, handleSubmit, formState: { errors }, reset } = useForm();


    const modalSubmit = formValues => {
        setLoading(true);

        ibService.rejectIb(customerId).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Partner rejected successfully');
            window.location.reload();
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to reject Partner');
        });
    };
    return (
        <Fragment>
            <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'danger', className: "m-1" }} >{'Reject'}</Btn>

            <CommonModal
                isOpen={modal}
                title={'Reject Partner'}
                toggler={toggle}
                formSubmit={() => { }}
                hideFooter={true}
            >
                <Form className="needs-validation" noValidate=""
                    onSubmit={handleSubmit(modalSubmit)}
                >
                    <Row>
                        <Col md='12 mb-3' >
                            <H4>Are you sure you want to reject Partner?</H4>
                        </Col>
                        <Col md={12} className="d-flex flex-row-reverse">
                            <Btn disabled={loading} attrBtn={{ color: 'danger' }} >{'Reject Partner'}</Btn>
                        </Col>
                    </Row>
                </Form>
            </CommonModal>
        </Fragment>
    );
};

export default RejectIb;
