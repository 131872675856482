import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Table,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Form,
  FormGroup,
} from 'reactstrap';
import { fetchfinreq, createfinreq, updatefinreq, deletefinreq } from 'Services/finreq.service';
import classnames from 'classnames';

const FinancialRequest = () => {
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || '1');
  const [finreqModal, setfinreqModal] = useState(false);
  const [finreqSearch, setfinreqSearch] = useState('');
  const [finreq, setfinreq] = useState([]);
  const [finreqformvalues, setfinreqformvalues] = useState([
    {
      trans_type: '',
      trans_name: '',
      amount: '',
      eff_on: '',
      status: '',
      notes: '',
      currency: 'AED',
    },
  ]);
  const [isfinreqEditMode, setIsfinreqEditMode] = useState(false);

  // Ensure tab is set correctly on first load
  useEffect(() => {
    if (!localStorage.getItem('activeTab')) {
      localStorage.setItem('activeTab', '1');
    }
  }, []);

  // Load financial request data when activeTab is '1'
  useEffect(() => {
    if (activeTab === '1') {
      loadfinreqData();
    }
  }, [activeTab]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      localStorage.setItem('activeTab', tab);
    }
  };

  const loadfinreqData = async () => {
    try {
      const fetchedfinreq = await fetchfinreq();
      // Handle the response based on expected structure (direct array)
      if (fetchedfinreq && Array.isArray(fetchedfinreq)) {
        setfinreq(fetchedfinreq);
      } else {
        console.error('Expected an array, but got:', fetchedfinreq);
      }
    } catch (error) {
      console.error('Error fetching and filtering finreq:', error);
    }
  };

  const toggleFinReqModal = () => setfinreqModal(!finreqModal);

  const handleFinReqFormChange = (e, index) => {
    const { name, value } = e.target;
    setfinreqformvalues((prevValues) => {
      const updatedValues = [...prevValues];
      updatedValues[index] = { ...updatedValues[index], [name]: value };
      return updatedValues;
    });
  };

  const initializeCreatefinreqForm = () => {
    setIsfinreqEditMode(false);
    setfinreqformvalues([
      {
        trans_type: 'Income',
        trans_name: '',
        amount: '',
        eff_on: '',
        status: '',
        notes: '',
        currency: 'AED',
      },
    ]);
    toggleFinReqModal();
  };

  const initializeUpdatefinreqForm = (finreq) => {
    setfinreqformvalues([finreq]);
    setIsfinreqEditMode(true);
    toggleFinReqModal();
  };

  const deleteFinReq = async (id) => {
    try {
      await deletefinreq(id);
      toast.success('Request deleted successfully!');
      setfinreq((prevDetails) => prevDetails.filter((finreq) => finreq.id !== id));
    } catch (error) {
      console.error('Error deleting Request:', error);
      toast.error('Failed to delete finreq. Please try again.');
    }
  };

  const handleSavefinreq = async (e) => {
    e.preventDefault();
    try {
      if (isfinreqEditMode) {
        await updatefinreq(finreqformvalues[0].id, finreqformvalues[0]);
        toast.success('Request updated successfully!');
      } else {
        await createfinreq(finreqformvalues[0]);
        toast.success('Request added successfully!');
      }
      await loadfinreqData();
      toggleFinReqModal();
    } catch (error) {
      console.error('Error saving Request:', error);
      toast.error('Failed to save finreq. Please try again.');
    }
  };

  const handleSearchfinreqChange = (e) => {
    setfinreqSearch(e.target.value);
  };

  const renderfinreqModal = () => {
    return (
      <Modal isOpen={finreqModal} toggle={toggleFinReqModal}>
        <ModalHeader toggle={toggleFinReqModal}>
          {isfinreqEditMode ? 'Edit Financial Request Info' : 'Add Financial Request Info'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSavefinreq}>
            {finreqformvalues.map((finreq, id) => (
              <div key={id}>
            
                <Input type="hidden" name="trans_type" value="Income" />
                <FormGroup>
                  <Label for={`trans_name_${id}`}>Transaction Name</Label>
                  <Input
                    type="select"
                    name="trans_name"
                    id={`trans_name_${id}`}
                    value={finreq.trans_name || ''}
                    onChange={(e) => handleFinReqFormChange(e, id)}
                    required
                  >
                    <option value="">Select Transaction</option>
                    <option value="Reimbursements">Reimbursements</option>
                    <option value="Advance salary - Income">Advance salary - Income</option>
                    <option value="Leave Encashment">Leave Encashment</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for={`amount_${id}`}>Amount</Label>
                  <Input
                    type="number"
                    name="amount"
                    id={`amount_${id}`}
                    value={finreq.amount || ''}
                    onChange={(e) => handleFinReqFormChange(e, id)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`eff_on_${id}`}>Effective On</Label>
                  <Input
                    type="date"
                    name="eff_on"
                    id={`eff_on_${id}`}
                    value={finreq.eff_on || ''}
                    onChange={(e) => handleFinReqFormChange(e, id)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`status_${id}`}>Status</Label>
                  <Input
                    type="select"
                    name="status"
                    id={`status_${id}`}
                    value={finreq.status || ''}
                    onChange={(e) => handleFinReqFormChange(e, id)}
                    required
                  >
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for={`currency_${id}`}>Currency</Label>
                  <Input
                    type="text"
                    name="currency"
                    id={`currency_${id}`}
                    value={finreq.currency || 'AED'}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`notes_${id}`}>Notes</Label>
                  <Input
                    type="textarea"
                    name="notes"
                    id={`notes_${id}`}
                    value={finreq.notes || ''}
                    onChange={(e) => handleFinReqFormChange(e, id)}
                  />
                </FormGroup>
              </div>
            ))}
            <ModalFooter>
              <Button color="primary" type="submit">
                {isfinreqEditMode ? 'OK' : 'Save'}
              </Button>
              <Button color="secondary" onClick={toggleFinReqModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      <Nav tabs>
        <NavItem>
        <style>
  {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
</style>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleTab('1')}
            style={{ cursor: 'pointer', color: activeTab === '1' ? '#194995' : 'black' }}
          >
            Financial Requests
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                Financial Requests
              </h3>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                  type="text"
                  placeholder="Search Financial Requests"
                  value={finreqSearch}
                  onChange={handleSearchfinreqChange}
                  style={{ width: '300px', marginBottom: '20px' }}
                />
                <Button onClick={initializeCreatefinreqForm} style={{ marginLeft: '10px' }}>
                  Add Financial Request
                </Button>
              </div>
            </CardHeader>
            <CardBody>
              <Table bordered hover responsive>
                <thead style={{ backgroundColor: '#E5E5E5', fontSize: '15px' }}>
                  <tr>
                    <th>Transaction Name</th>
                    <th>Transaction Type</th>
                    <th>Amount</th>
                    <th>Effective On</th>
                    <th>Status</th>
                    <th>Notes</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {finreq
                    .filter((item) =>
                      Object.values(item).some((val) =>
                        String(val).toLowerCase().includes(finreqSearch.toLowerCase())
                      )
                    )
                    .map((finreq) => (
                      <tr key={finreq.id}>
                        <td>{finreq.trans_name}</td>
                        <td>{finreq.trans_type}</td>
                        <td>{finreq.amount}</td>
                        <td>{finreq.eff_on}</td>
                        <td>{finreq.status}</td>
                        <td>{finreq.notes}</td>
                        <td>
                          <Button
                            size="sm"
                            onClick={() => initializeUpdatefinreqForm(finreq)}
                            style={{ marginRight: '10px' }}
                          >
                            Edit
                          </Button>
                          <Button size="sm" color="danger" onClick={() => deleteFinReq(finreq.id)}>
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
      {renderfinreqModal()}
    </>
  );
};

export default FinancialRequest;
