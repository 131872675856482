import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Bar } from "react-chartjs-2"; // Import Bar chart from react-chartjs-2
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Grid } from '@mui/material';
ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const ActiveIBReport = ({ searchTerm, startDate, endDate,showCharts }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]); // State for group members

  // Access roleId and userId from Redux store
  const profile = useSelector((state) => state.login && state.login.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
  };

  const columns = [
    {
      name: "Created Date",
      selector: (row) => formatDate(row.CreatedDate),
      sortable: true,
    },
    { name: "IB ID", selector: (row) => row.IBID, sortable: true },
    { name: "IB Name", selector: (row) => row.IBname, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    {
      name: "Rebate",
      selector: (row) => `$${Number(row.Rebate || 0).toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Payout",
      selector: (row) => `$${Number(row.Payout || 0).toLocaleString()}`,
      sortable: true,
    },
    { name: "Client", selector: (row) => row.client_count, sortable: true },
  ];

  
      // Fetch group members by userId
        useEffect(() => {
          if (userId) {
            const fetchGroupMembers = async () => {
              try {
                const response = await SalesReportService.getGroupMembersById(userId);
                // console.log("Full Group Members Response:", response);
                    setGroupMembers(response); // Store the group members
              } catch (err) {
                console.error("Failed to fetch group members:", err);
                toast.error("Failed to fetch group members.");
                setGroupMembers([]); // Reset group members if the API fails
              }
            };
      
            fetchGroupMembers();
          }
        }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await SalesReportService.getActiveIB();

             // Filter data based on role and group members
                 let filteredResponse = response;
               
         
                 // If the role is not in the specified ones and groupMembers is not empty, filter by group members
                 if (roleId !== 33 && roleId !== 18 && roleId !== 41) {
                   if (groupMembers.length > 0) {
                     // Filter deposits by matching agentId with group members
                     filteredResponse = response.filter((item) =>
                       groupMembers.includes(item.agentId)
                     );
                   } else {
                     // If no group members are found, filter by userId
                     filteredResponse = response.filter((item) => item.agentId === userId);
                   }
                 }
         
                 setData(filteredResponse);
                 setFilteredData(filteredResponse);
               } catch (err) {
                 setError("Failed to fetch data.");
                 toast.error("Failed to fetch data.");
               } finally {
                 setLoading(false);
               }
             };
         
             fetchData();
           }, [roleId, userId, groupMembers]);

  useEffect(() => {
    let filtered = data;

    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }

    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.first_createdAt) >= new Date(startDate)
      );
    }

    if (endDate) {
      filtered = filtered.filter(
        (item) => new Date(item.last_createdAt) <= new Date(endDate)
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  // Prepare the data for the Bar Chart
  const getBarChartData = () => {
    // Prepare data for each IB: Rebate, Payout, and Client
    const ibData = filteredData.reduce((acc, item) => {
      const ibName = item.IBname; // Group by IB Name
  
      // Initialize the entry for the IB if it doesn't exist
      if (!acc[ibName]) {
        acc[ibName] = {
          rebate: 0,
          payout: 0,
          client: 0,
        };
      }
  
      // Sum up the values for each category
      acc[ibName].rebate += item.Rebate || 0;
      acc[ibName].payout += item.Payout || 0;
      acc[ibName].client += item.client_count || 0;
  
      return acc;
    }, {});
  
    const labels = Object.keys(ibData); // IB Names as labels for the x-axis
    return {
      labels: labels, // X-axis: IB Names
      datasets: [
        {
          label: "Rebate",
          data: labels.map((ibName) => ibData[ibName].rebate),
          backgroundColor: "#086CA2", // Color for Rebate
          borderColor: "#086CA2",
          borderWidth: 1,
          categoryPercentage: 0.5,
          barPercentage: 0.8,
        },
        {
          label: "Payout",
          data: labels.map((ibName) => ibData[ibName].payout),
          backgroundColor: "#FF5733", // Color for Payout
          borderColor: "#FF5733",
          borderWidth: 1,
          categoryPercentage: 0.5,
          barPercentage: 0.8,
        },
        {
          label: "Client",
          data: labels.map((ibName) => ibData[ibName].client),
          backgroundColor: "#28A745", // Color for Client
          borderColor: "#28A745",
          borderWidth: 1,
          categoryPercentage: 0.5,
          barPercentage: 0.8,
        },
      ],
    };
  };
  


  return (
    <div style={{ position: "relative" }}>
               <style>
        {`
          // .chartbutton {
          //  display: none;
          // }
        `}
      </style>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : filteredData.length > 0 ? (
        <Grid container spacing={1}>
        {/* Bar chart only */}
        {showCharts && (
        <Grid item xs={12} md={9}>
          <div style={{ marginBottom: "20px", width: "100%" }}>
          <Bar
          data={getBarChartData()}
          options={{
            responsive: true,
            scales: {
              x: {
                stacked: true,
              },
              y: {
                stacked: true,
              },
            },
          }}
        />

          </div>
        </Grid>
        )}
        {/* DataTable */}
        <Grid item xs={12} md={12}>
          <DataTable
            columns={columns}
            data={filteredData || []}
            pagination
            striped
            persistTableHead
          />
        </Grid>
      </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          No data available.
        </div>
      )}
    </div>
  );
};

export default ActiveIBReport;
