import React, { Fragment, useEffect, useState, useCallback } from 'react';
import {
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Leads } from '../../../Constant';
import { H5, Btn } from '../../../AbstractElements';
import { leadService, activitiesService } from 'Services';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import TableLoader from 'CommonElements/Table/TableLoader';
import TablePagination from 'CommonElements/Table/TablePagination';
import DataTable from 'react-data-table-component';
import AssignModal from './Modal/assignTo';
import LeadModal from './Modal/uploadLeads';
import { writeFile, utils } from 'xlsx';
import LeadModalButton from './LeadModalButton';
import { useSelector } from 'react-redux';
import { getMenuPerm } from 'utils';
import Select from 'react-select';
import Filter from '../../Common/Component/filter';



const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}
const HoverableRowsClass = () => {
  const [rec, setRec] = useState({});
  const [modal, setModal] = useState(false);
  const [leadBulkModal, setLeadBulkModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [subLeads, setSubLeads] = useState(false);
  const [modalMessage, setmodalMessage] = useState('');
  let [searchValue, setSearchValue] = useState({});
  const [limit, setLimit] = useState(25);
  const [initialPage, setInitialPage] = useState(1);

  const query = useQuery();
  const stateQuery = query.get('state');
  const pageQuery = query.get('page');
  let queryData = '';
  const mainGroup = useSelector((state) => state.login?.profile?.mainGroup);
  const mainGroups = useSelector((state) => state.login);
  console.log("Groupd Id is ", mainGroup);
  console.log("Profile data is ", mainGroups);
  const options = [
    { value: '25', label: '25' },
    { value: '50', label: '50' },
    { value: '100', label: '100' },
  ]
  // Function to handle limit change
  const handleLimitChange = (selectedOption) => {
    const newLimit = selectedOption.value; // Use the selected option's value directly
    setLimit(newLimit); // Update the limit state
    //loadData(1,newLimit);
    appendParameterToUrl('limit', newLimit);
  };

  const appendParameterToUrl = (key, value) => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Create a URL object
    const url = new URL(currentUrl);

    if (key == 'subLeads') {
      url.search = '';
    }
    if (key == 'callStatus' || key == 'limit' || key == 'dateFrom' || key == 'dateFrom' || key == 'agentId' || key == 'firstName') {
      url.searchParams.set('page', 1);
    }

    // Check if the parameter exists
    if (url.searchParams.has(key)) {
      // Update the existing parameter
      url.searchParams.set(key, value);
    } else {
      // Append the new parameter
      url.searchParams.append(key, value);
    }

    if (key == 'callStatus' && value == '') {
      console.log("value is ", value);
      url.searchParams.delete(key);
    }

    // Update the browser's address bar
    window.history.pushState(null, '', url.toString());
    loadData();
    console.log("Updated URL:", url.toString());
  };

  // Function to separate page and limit, and construct body params
  const getParamsAndBody = (url) => {
    const queryParams = extractUrlParams(url);

    const page = queryParams.page || 1;
    const limit = queryParams.limit || 25;
    setLimit(limit);
    // Remove page and limit from queryParams to construct the body parameters
    delete queryParams.page;
    delete queryParams.limit;

    const bodyParams = queryParams;

    return {
      page,
      limit,
      bodyParams
    };
  };
  // Function to extract query parameters from the URL
  const extractUrlParams = (url) => {
    const urlObj = new URL(url);
    const params = new URLSearchParams(urlObj.search);
    const queryParams = {};
    for (const [key, value] of params) {
      queryParams[key] = value;
    }

    return queryParams;
  };

  const handleSubLeads = () => {
    appendParameterToUrl('subLeads', 'true');
  }
  function exportToExcel(data) {
    const worksheet = utils.json_to_sheet(data);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'lead_sheet');
    return workbook;
  }
  const handleExport = () => {
    const workbook = exportToExcel(selectedRows);
    writeFile(workbook, 'lead_list.xlsx');

    var postData = {
      type: 'Lead Data Export',
      customerId: user.id,
      userId: user.id,
      note: {'note': 'lead_list.xlsx'},
    };
    postActivities(postData);

  };
  
  const clearSelectedRow = () => {
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };
  const toggle = (e) => {
    if (e.target.textContent == 'Sales Assign') {
      setmodalMessage('Assign to');
    } else if (e.target.textContent == 'Delete Leads') {
      setmodalMessage('Delete Leads');
    }
    setModal(!modal);
  };
  const leadUploadToggle = (e) => {
    setLeadBulkModal(!leadBulkModal);
  };
  const tableColumns = [
    {
      name: 'Name',
      sortable: true,
      center: true,
      cell: (item) => (
        <Link className=''
          to={{
            pathname: `/leads/${item.id}`,
            //search: `${query}`
          }}>
          {`${item.firstName}`}
        </Link>
      ),
    },
    {
      name: 'Email',
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isEmailView ? (
            <span>
              ******
              <i
                className='fa fa-eye-slash'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.email}
              <i
                className='fa fa-eye'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'emailView')
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: 'Phone',
      sortable: false,
      center: true,
      cell: (item, index) => (
        <div>
          {!item.isPhoneView ? (
            <span>
              ******
              <i
                className='fa fa-eye-slash'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'phoneView')
                }
              ></i>
            </span>
          ) : (
            <span>
              {item.phone}
              <i
                className='fa fa-eye'
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={(e) =>
                  emailPhoneViewClick(e, index, item.id, 'phoneView')
                }
              ></i>
            </span>
          )}
        </div>
      ),
    },
    {
      name: 'Owner',
      selector: (row) => `${row['Agent.firstName'] || ''} ${row['Agent.lastName'] || ''}`,
      sortable: true,
      center: true,
    },
    {
      name: 'Date',
      selector: (row) => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
      center: true,
    },
    {
      name: 'Country',
        selector: (row) => row.country,
        sortable: true,
        center: true,
    },
    {
      name: 'Status',
      selector: (row) => row.callStatus,
      sortable: true,
      center: true,
    },
    {
      name: 'Partner',
      selector: (row) => `${row['Parent.firstName'] || ''} ${row['Parent.lastName'] || ''}`,
      sortable: false,
      center: true,
    },
  ];
  useEffect(() => {
    loadData();
    console.log("GVelkrjwl");
  }, [searchValue, initialPage]);
  const permissions = useSelector(
    (state) =>
      (state.login &&
        state.login.profile &&
        state.login.profile.Role &&
        state.login.profile.Role.permissions) ||
      {}
  );

  const user = useSelector(
    (state) =>
      (state.login &&
        state.login.profile) ||
      {}
  );

  const emailPhoneViewClick = (e, index, customerId, type) => {
    e.preventDefault();
    var updatedRecord = rec;
    var isActive = false;
    var activityType = '';
    if (type === 'emailView') {
      activityType = 'email';
      isActive = updatedRecord.data[index].isEmailView === true ? false : true;
      updatedRecord.data[index].isEmailView = updatedRecord.data[
        index
      ].isEmailView = isActive;
    } else if (type === 'phoneView') {
      activityType = 'phone';
      isActive = updatedRecord.data[index].isPhoneView === true ? false : true;
      updatedRecord.data[index].isPhoneView = updatedRecord.data[
        index
      ].isPhoneView = isActive;
    }
    if (isActive) {
      var postData = {
        type: activityType,
        customerId: customerId,
        userId: user.id,
        note: {'note': activityType + ' View '},
      };
      postActivities(postData);
    }
    setRec({
      ...updatedRecord,
      loading: false,
    });
  };
  const postActivities = (payload) => {
    activitiesService
      .postActivity(payload)
      .then((res) => { })
      .catch((err) => {
      });
  };
  const loadData = () => {
    const currentUrl = window.location.href;
    let { page, limit, bodyParams } = getParamsAndBody(currentUrl);

    /*if(pageQuery){
      page = pageQuery;
    }
    if(stateQuery){
      searchValue = JSON.parse(stateQuery);
    }
    Object.keys(searchValue).length > 0 ?
      console.log("search is", searchValue)
      :
      setRec({
        loading: true,
    })*/
    setInitialPage(bodyParams.page);
    bodyParams.isLead = true;
    (!bodyParams.page || !bodyParams.limit) &&
      setRec({
        loading: true,
      })
    console.log("Body Params are", bodyParams);
    //searchValue.isLead = true;
    leadService
      .searchCustomers({
        page,
        limit,
        ...bodyParams,
      })
      .then((res) => {
        if (res.data.length > 0) {
          console.log('data is', res.data);
          res.data = res.data.map((item) => ({
            ...item,
            isEmailView: false,
            isPhoneView: false,
          }));
        }
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
        console.log("Error getting leads ", err);
      });
  };
  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
    //console.log('Selected Rows ',state.selectedRows);
  }, []);

  const handleFilter = (values) => {
    setSearchValue(values);
    //pageNumber = 1;
    //navigate(`?page=${pageNumber}`);
    console.log("handle filter ");
  };
  const { formState: { errors }, setValue, getValues, register, control } = useForm();
  return (
    <Fragment>

      {rec.loading && <TableLoader />}
      {!rec.loading && (
        <Col sm='12'>
          <Card>
            <CardHeader>
              <Row>
                <Col md='2'>
                  <H5>{Leads}</H5>
                </Col>
                <Col md='10'>
                  <Row
                  // className={
                  //   selectedRows.length > 0 ? "float-start" : "float-end"
                  // }
                  >
                    {selectedRows.length > 0 ? (
                      <Col md='2'>
                        <Btn attrBtn={{ color: 'primary', onClick: toggle }}>
                          Sales Assign
                        </Btn>
                        <AssignModal
                          modal={modal}
                          toggle={toggle}
                          setModal={setModal}
                          selectedSales={selectedRows}
                          checkBoxClear={clearSelectedRow}
                          loadLeadData={loadData}
                          titleMessage={modalMessage}
                        />
                      </Col>
                    ) : (
                      <Col md='3'></Col>
                    )}

                    {getMenuPerm(permissions, 'leads:delete') === 1 && selectedRows.length > 0 && (
                      <Col md='2'>
                        <Btn
                          attrBtn={{
                            color: 'secondary',
                            onClick: toggle,
                          }}
                        >
                          Delete Leads
                        </Btn>
                      </Col>
                    )}
                    <Col md='2'>
                      <Btn
                        attrBtn={{
                          color: 'btn btn-outline-primary ms-2',
                          onClick: leadUploadToggle,
                        }}
                      >
                        <i
                          className='fa fa-upload'
                          style={{ padding: '3px' }}
                        ></i>
                        <LeadModal
                          modal={leadBulkModal}
                          NewMessage='Upload Leads'
                          toggle={leadUploadToggle}
                          setModal={setLeadBulkModal}
                        />
                        Upload
                      </Btn>
                    </Col>
                    {getMenuPerm(permissions, 'leads:export') === 1 && rec.data && (
                      <Col md='1'>
                        <Btn
                          attrBtn={{
                            color: 'success',
                            onClick: handleExport,
                          }}
                        >
                          Export
                        </Btn>
                      </Col>
                    )}
                    <Col md={'1'}>
                      {getMenuPerm(permissions, 'leads:create') === 1 && (
                        <LeadModalButton btnText='Add' defaultVal='Godo' loadData={loadData} />
                      )}
                    </Col>
                    <Col md="2">
                      <Select
                        defaultValue={options.find(option => option.value === limit.toString())}
                        onChange={handleLimitChange}
                        options={options}
                      />
                    </Col>
                    { user && user.userGroups?.length > 0 && <Col md='2'>
                      <Btn
                        attrBtn={{
                          color: 'success',
                          onClick: handleSubLeads,
                        }}
                      >
                        Sub Leads
                      </Btn>
                    </Col>}
                  </Row>
                </Col>
              </Row>
            </CardHeader>
            <div className='table-responsive'>
              <Container fluid={true} className='datatables'>
                <Row>
                  <Col sm='12'>
                    <Card>
                      {rec.data ? (
                        <CardBody>
                          <Row className='mb-3'>
                            <Filter filter={appendParameterToUrl} IbInfo={rec} show={'isLead'} />
                          </Row>
                          <DataTable
                            data={rec.data}
                            columns={tableColumns}
                            striped={true}
                            center={true}
                            selectableRows
                            persistTableHead
                            onSelectedRowsChange={handleRowSelected}
                            // contextActions={contextActions}
                            clearSelectedRows={toggleCleared}
                          />
                        </CardBody>
                      ) : null}
                    </Card>
                  </Col>
                </Row>
              </Container>
              <TablePagination data={rec} loadData={loadData} limit={limit} param={appendParameterToUrl} />
            </div>
          </Card>
        </Col>
      )}
    </Fragment>
  );
};

export default HoverableRowsClass;
