import React, { useState ,useEffect} from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Input,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form, FormGroup,
  Label
} from 'reactstrap';
import classnames from 'classnames';
import { fetchloanreq, createloanreq, updateloanreq, deleteloanreq } from 'Services/loanreq.service';


const LoanRequests = () => {
  const [loanModal, setloanModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [loanformdata, setloanformdata] = useState({
    loanType: '',
    effectiveDate: '',
    loanAmount: '',
    installment: '',
    numberOfPayments: '',
    notes: '',
    currency: 'AED',
    remainingInstallment: '',
  });

  const [loanRequestsData, setLoanRequestsData] = useState([]); // Ensure this is initialized as an empty array

  useEffect(() => {
    // Fetch existing loan requests on component mount
    const loadLoanRequests = async () => {
      try {
        const data = await fetchloanreq(); // Fetch loan requests
        console.log("Fetched loan requests:", data); // Log the data for debugging
        // Ensure that data is an array
        if (Array.isArray(data)) {
          setLoanRequestsData(data);
        } else {
          console.error("Expected an array, but got:", data);
        }
      } catch (error) {
        console.error("Error fetching loan requests:", error);
      }
    };

    loadLoanRequests();
  }, []);

  const handleRequestClick = () => {
    setloanModal(true);
    // Reset form data for new loan request
    setloanformdata({
      loanType: '',
      effectiveDate: '',
      loanAmount: '',
      installment: '',
      numberOfPayments: '',
      notes: '',
      currency: 'AED',
      remainingInstallment: '',
    });
  };

  const toggleLoanModal = () => {
    setloanModal(!loanModal);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let updatedFormData = { ...loanformdata, [name]: value };

    // Update installment if loanAmount and numberOfPayments are filled
    if (name === 'loanAmount' || name === 'numberOfPayments') {
      const loanAmount = updatedFormData.loanAmount || 0;
      const numberOfPayments = updatedFormData.numberOfPayments || 1; // avoid division by zero

      if (loanAmount && numberOfPayments) {
        updatedFormData.installment = (parseFloat(loanAmount) / parseInt(numberOfPayments)).toFixed(2);
      }
    }

    // Calculate remainingInstallment if installment is filled
    if (name === 'installment' || name === 'numberOfPayments') {
      const installment = updatedFormData.installment || 0;
      const numberOfPayments = updatedFormData.numberOfPayments || 1;

      // For simplicity, assuming no payments have been made initially
      updatedFormData.remainingInstallment = (parseFloat(installment) * parseInt(numberOfPayments)).toFixed(2);
    }

    setloanformdata(updatedFormData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await createloanreq(loanformdata);
      console.log('Form Submitted:', response);
      // Optionally refresh loan requests after creation
      const updatedRequests = await fetchloanreq();
      if (Array.isArray(updatedRequests)) {
        setLoanRequestsData(updatedRequests);
      } else {
        console.error("Expected an array after creation, but got:", updatedRequests);
      }
    } catch (error) {
      console.error("Error creating loan request:", error);
    } finally {
      toggleLoanModal(); // Close the modal after submitting
    }
  };

  const handleDeleteLoanRequest = async (id) => {
    try {
      await deleteloanreq(id);
      // Refresh loan requests after deletion
      const updatedRequests = await fetchloanreq();
      if (Array.isArray(updatedRequests)) {
        setLoanRequestsData(updatedRequests);
      } else {
        console.error("Expected an array after deletion, but got:", updatedRequests);
      }
    } catch (error) {
      console.error("Error deleting loan request:", error);
    }
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleSearchLoanChange = (e) => {
    // Logic for handling search input change
  };



  const renderLoanModal = () => (
    <Modal isOpen={loanModal} toggle={toggleLoanModal}>
      <ModalHeader toggle={toggleLoanModal}>
        Loan Request
      </ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="loanType">Loan Type*</Label>
            <Input
              type="select"
              name="loanType"
              value={loanformdata.loanType}
              onChange={handleChange}
              required
            >
              <option value="">Please select</option>
              <option value="personal">Personal Loan</option>
            </Input>
          </FormGroup>

          <FormGroup>
            <Label for="effectiveDate">Effective Date*</Label>
            <Input
              type="date"
              name="effectiveDate"
              value={loanformdata.effectiveDate}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="loanAmount">Loan Amount*</Label>
            <Input
              type="text"
              name="loanAmount"
              value={loanformdata.loanAmount}
              onChange={handleChange}
              required
            />
            <span>AED</span>
          </FormGroup>

          <FormGroup>
            <Label for="installment">Installment*</Label>
            <Input
              type="text"
              name="installment"
              value={loanformdata.installment}
              onChange={handleChange}
              readOnly
            />
            <span>AED</span>
          </FormGroup>

          <FormGroup>
            <Label for="numberOfPayments">Number of Payments*</Label>
            <Input
              type="text"
              name="numberOfPayments"
              value={loanformdata.numberOfPayments}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <FormGroup>
            <Label for="remainingInstallment">Remaining Installment</Label>
            <Input
              type="text"
              name="remainingInstallment"
              value={loanformdata.remainingInstallment}
              readOnly
            />
            <span>AED</span>
          </FormGroup>

          <FormGroup>
            <Label for="currency">Currency</Label>
            <Input type="text" name="currency" value={loanformdata.currency} readOnly />
          </FormGroup>

          <FormGroup>
            <Label for="notes">Notes</Label>
            <Input
              type="textarea"
              name="notes"
              value={loanformdata.notes}
              onChange={handleChange}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" type="submit" onClick={handleSubmit}>
          Send Request
        </Button>
        <Button color="secondary" onClick={toggleLoanModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );

  return (
    <>
      <Nav tabs>
      <style>
  {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
</style>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => toggleTab('1')}
            style={{ cursor: 'pointer', color: activeTab === '1' ? '#194995' : 'black' }}
          >
            Loan Requests
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                Loan Requests
              </h3>
             
             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
     
                <Input
                  type="text"
                  placeholder="Search Loan Requests"
                  onChange={handleSearchLoanChange}
                  style={{ width: '300px', marginBottom: '20px' }}
                />
                <Button onClick={handleRequestClick} style={{ marginLeft: '10px' }}>
                  Request Loan
                </Button>
              </div>
            </CardHeader>
            <CardBody className="scrollable-table-container">
              <Table bordered hover className="scrollable-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Amount</th>
                    <th>Installment</th>
                    <th>Number of Payments</th>
                    <th>Remaining Installment Amount</th>
                    <th>Effective Date</th>
                    <th>Paid</th>
                    <th>Out-of-payroll</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loanRequestsData.map((loan, index) => (
                    <tr key={index}>
                      <td>{loan.name}</td>
                      <td>{loan.amount} AED</td>
                      <td>{loan.installment} AED</td>
                      <td>{loan.payments}</td>
                      <td>{loan.remainingInstallment} AED</td>
                      <td>{loan.effectiveDate}</td>
                      <td>{loan.paid}</td>
                      <td>{loan.outOfPayroll}</td>
                      <td>{loan.status}</td>
                      <td>
                        <Button size="sm" color="primary" style={{ marginRight: '10px' }}>
                          Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>
      {renderLoanModal()}
    </>
  );
};

export default LoanRequests;
