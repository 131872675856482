//Pages
import Users from "../Components/Pages/Users";
import AccountTypesList from "../Components/Pages/AccountTypes/AccountTypesList";
import UserLogs from "../Components/Pages/UserLogs";
import EmailLogs from "../Components/Pages/EmailLog";

import Roles from "../Components/Pages/Roles/RolesList";
import DashboardDefault from "../Components/Pages/Dashboard/Default";
import SystemEmails from "../Components/Pages/SystemEmails/SystemEmailsList";
import Deposits from "../Components/Pages/Deposits";
import Withdrawals from "../Components/Pages/Withdrawals";
import InternalTransfer from "../Components/Pages/InternalTransfer";
import IbRequests from "../Components/Pages/IbRequests/IbRequestsList";
import AccountRequests from "../Components/Pages/AccountRequests/AccountRequestsList";
import Clients from "../Components/Pages/Clients";
import ActionNeeded from "../Components/Pages/Clients/ActionNeeded";
import MoroccoDocuments from "../Components/Pages/ClientDetail/Documents/MoroccoDocuments";
import OtherClients from '../Components/Pages/Clients/OtherClients';
// import Detail from "../Components/Pages/Clients/ContactApp";
import ClientDetail from "../Components/Pages/ClientDetail/ClientDetail";
import ErrorPage4 from "../Components/Pages/ErrorPages/ErrorPage404";
import BannersList from "../Components/Pages/Banners/BannersList";
import VideosList from "../Components/Pages/Videos/VideosList";
import GroupList from "../Components/Pages/Groups/GroupList";
//import VideosList from "../Components/Pages/Videos/VideosList";

import Leads from "../Components/Pages/Leads";
import Ibs from "../Components/Pages/Ibs";

import Reports from "../Components/Pages/Reports";
import Editprofile from "../Components/Pages/Profile/Editprofile";

// import TicketList from "../Components/Pages/TicketList/TicketList";
import Tickets from "../Components/Todo/index";
import ExtensionNumber from "Components/Pages/ExtensionNumber/ExtensionNumber";
// import Documents from "Components/Pages/ComplianceDocuments/Documents";
import Marketing from "../Components/Pages/Marketing/index";

import BonusPage from "Components/Pages/BonusPlugin/BonusPage";
import BonusReport from "Components/Pages/BonusPlugin/BonusReport";
import TradingGroup from "Components/Pages/TradingGroup/TradingGroup";

import SwapReport from "Components/Pages/Operation/SwapReport";
import SwapGroupMapping from "Components/Pages/Operation/SwapGroupMapping";

import MarketReport from "../Components/Pages/MarketReport";
import SalesReports from "Components/Pages/SalesReports/SalesReports";

// Hrms........................................................
import HrmsAdminDashboard from "Components/Pages/Hrms/AdminDashboard";
import AdminEmployeeManagement from "Components/Pages/Hrms/AdminEmployeeManagement";
import AdminTimeManagment from "Components/Pages/Hrms/AdminTimeManagment";
import Attendance from "Components/Pages/Hrms/Attendance & Time Tracking";
import DocumentsLetters from "Components/Pages/Hrms/Documents & Letters";
import ManageTasks from "Components/Pages/Hrms/ManageTasks";
import FinancialRequest from "Components/Pages/Hrms/User Pages/Financial Requests";
import LoanRequests from "Components/Pages/Hrms/User Pages/Loan Requests";
import EmployeeSalaries from "Components/Pages/Hrms/EmployeeSalaries";
import EndOfService from "Components/Pages/Hrms/EndOfService";


export const routes = [
  //page
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <DashboardDefault />,
  },
  { path: `${process.env.PUBLIC_URL}/users`, Component: <Users /> },
  {
    path: `${process.env.PUBLIC_URL}/account-types`,
    Component: <AccountTypesList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/banners`,
    Component: <BannersList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/groups`,
    Component: <GroupList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/videos`,
    Component: <VideosList />,
  },
  { path: `${process.env.PUBLIC_URL}/roles`, Component: <Roles /> },
  {
    path: `${process.env.PUBLIC_URL}/settings/system-emails`,
    Component: <SystemEmails />,
  },
  { path: `${process.env.PUBLIC_URL}/settings/user-logs`, Component: <UserLogs /> },
  { path: `${process.env.PUBLIC_URL}/settings/email-logs`, Component: <EmailLogs /> },

  { path: `${process.env.PUBLIC_URL}/deposits`, Component: <Deposits /> },
  { path: `${process.env.PUBLIC_URL}/withdrawals`, Component: <Withdrawals /> },
  {
    path: `${process.env.PUBLIC_URL}/internal-transfers`,
    Component: <InternalTransfer />,
  },
  { path: `${process.env.PUBLIC_URL}/ib-requests`, Component: <IbRequests /> },
  // { path: `${process.env.PUBLIC_URL}/ticket-list`, Component: <Tickets /> },
  // { path: `${process.env.PUBLIC_URL}/ticket-list`, Component: <TicketList /> },
  { path: `${process.env.PUBLIC_URL}/tickets`, Component: <Tickets /> },
  {
    path: `${process.env.PUBLIC_URL}/account-requests`,
    Component: <AccountRequests />,
  },
  { path: `${process.env.PUBLIC_URL}/clients/all-clients`, Component: <Clients /> },
  { path: `${process.env.PUBLIC_URL}/clients/action-needed`, Component: <ActionNeeded /> },
  { path: `${process.env.PUBLIC_URL}/leads`, Component: <Leads /> },
  { path: `${process.env.PUBLIC_URL}/ibs`, Component: <Ibs /> },

  {
    path: `${process.env.PUBLIC_URL}/clients/:id`, Component: <ClientDetail />,
  },
  { path: `${process.env.PUBLIC_URL}/clients/other-clients-x-m`, Component: <OtherClients /> },
  { path: `${process.env.PUBLIC_URL}/clients/morocco-documents`, Component: <MoroccoDocuments /> },

  { path: `${process.env.PUBLIC_URL}/leads/:id`, Component: <ClientDetail /> },
  { path: `${process.env.PUBLIC_URL}/ibs/:id`, Component: <ClientDetail /> },

  { path: `${process.env.PUBLIC_URL}/financial-reports`, Component: <Reports /> },
  // { path: `${process.env.PUBLIC_URL}/reports`, Component: <Reports /> },
  { path: `${process.env.PUBLIC_URL}/profile`, Component: <Editprofile /> },
  
  // extension number
  { path: `${process.env.PUBLIC_URL}/directory`, Component: <ExtensionNumber /> },

  // compliance document
  // { path: `${process.env.PUBLIC_URL}/clients/documents`, Component: <Documents /> },

  {
    path: `${process.env.PUBLIC_URL}/page-not-found`,
    Component: <ErrorPage4 />,
  },

  { path: `${process.env.PUBLIC_URL}/marketing-dashboard`, Component: <Marketing /> },
  { path: `${process.env.PUBLIC_URL}/marketing-report`, Component: <MarketReport/> },

  {
    path: `${process.env.PUBLIC_URL}/bonus-plugin`,
    Component: <BonusPage/>,  
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-report`,
    Component: <BonusReport/>,  
  },
  {
    path: `${process.env.PUBLIC_URL}/trading-group-defined`,
    Component: <TradingGroup/>,  
  },
  
  {
    path: `${process.env.PUBLIC_URL}/swap-report`,
    Component: <SwapReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/swapgroup-mapping`,
    Component: <SwapGroupMapping/>,
  },
  { path: `${process.env.PUBLIC_URL}/SalesReport`, Component: <SalesReports/> },

  // HRMS..................................................................
  {
    path: `${process.env.PUBLIC_URL}/HrmsAdminDashboard`,
    Component: <HrmsAdminDashboard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/AdminEmployeeManagement`,
    Component: <AdminEmployeeManagement />,
  },
  {
    path: `${process.env.PUBLIC_URL}/AdminTimeManagment`,
    Component: <AdminTimeManagment />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Attendance`,
    Component: <Attendance />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Documents & Letters`,
    Component: <DocumentsLetters />,
  },
  {
    path: `${process.env.PUBLIC_URL}/Manage Tasks`,
    Component: <ManageTasks />,
  },
  {
    path: `${process.env.PUBLIC_URL}/FinancialRequests`,
    Component: <FinancialRequest />,
  },
  {
    path: `${process.env.PUBLIC_URL}/LoanRequests`,
    Component: <LoanRequests />,
  },
  {
    path: `${process.env.PUBLIC_URL}/EmployeeSalaries`,
    Component: <EmployeeSalaries />,
  },
  {path: `${process.env.PUBLIC_URL}/EndOfService`,
   Component: <EndOfService/>,
  },
];
