import React from 'react';
import { Card, CardHeader, CardBody, Col } from 'reactstrap';
import UpdateAgreement from '../../Pages/ClientDetail/Agreements/EditAgreementByID';

import { getMenuPerm } from 'utils';
const CPAAgreements = ({ agreement = {}, userProfile, customerId, loadData, permissions, data, ibType }) => {
    // console.log("userProfile is: ", userProfile.entiry);
    const signupLink = `https://signup.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
    const demoLink = `https://signup-demo.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
    const partnerLink = `https://signup-partner.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
    return (<React.Fragment>
        <Col sm={6}>
            <Card>
                <CardHeader className='p-3'>Dedicated Links</CardHeader>
                <CardBody>
                    <p className='mb-0 ml-2'>
                        Register Demo &nbsp;{' '}
                        <a className='pl-3' href={demoLink + `&agr=0`}>
                            Demo
                        </a>
                    </p>
                    <p className='mb-0 ml-2'>
                        Register Live &nbsp;&nbsp;&nbsp;{' '}
                        <a className='pl-3' href={signupLink + `&agr=0`}>
                            Live
                        </a>
                    </p>
                    <p className='mb-0 ml-2'>
                        Register Sub Ib{' '}
                        <a className='pl-3' href={partnerLink + `&agr=0`}>
                            Sub IB
                        </a>
                    </p>
                </CardBody>
            </Card>
        </Col>
    </React.Fragment>
    );
};
export default CPAAgreements;