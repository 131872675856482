import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const InActiveIBReport = ({ searchTerm, startDate, endDate }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]); // State for group members

  // Access roleId and userId from Redux store
  const profile = useSelector((state) => state.login && state.login.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
  };

  const columns = [
    {
      name: "Created Date",
      selector: (row) => formatDate(row.CreatedDate),
      sortable: true,
    },
    { name: "IB ID", selector: (row) => row.IBID, sortable: true },
    { name: "IB Name", selector: (row) => row.IBName, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    {
      name: "Rebate",
      selector: (row) => `$${Number(row.OutStandingRebate || 0).toLocaleString()}`,
      sortable: true,
    },
    {
      name: "Payout",
      selector: (row) => `$${Number(row.Payout || 0).toLocaleString()}`,
      sortable: true,
    },
    { name: "Client", selector: (row) => row.ClientCount, sortable: true },
  ];


    // Fetch group members by userId
      useEffect(() => {
        if (userId) {
          const fetchGroupMembers = async () => {
            try {
              const response = await SalesReportService.getGroupMembersById(userId);
              // console.log("Full Group Members Response:", response);
                  setGroupMembers(response); // Store the group members
            } catch (err) {
              console.error("Failed to fetch group members:", err);
              toast.error("Failed to fetch group members.");
              setGroupMembers([]); // Reset group members if the API fails
            }
          };
    
          fetchGroupMembers();
        }
      }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError("");

      try {
        const response = await SalesReportService.getInActiveIB();

         // Filter data based on role and group members
            let filteredResponse = response;
          
    
            // If the role is not in the specified ones and groupMembers is not empty, filter by group members
            if (roleId !== 33 && roleId !== 18 && roleId !== 41) {
              if (groupMembers.length > 0) {
                // Filter deposits by matching agentId with group members
                filteredResponse = response.filter((item) =>
                  groupMembers.includes(item.agentId)
                );
              } else {
                // If no group members are found, filter by userId
                filteredResponse = response.filter((item) => item.agentId === userId);
              }
            }
    
            setData(filteredResponse);
            setFilteredData(filteredResponse);
          } catch (err) {
            setError("Failed to fetch data.");
            toast.error("Failed to fetch data.");
          } finally {
            setLoading(false);
          }
        };
    
        fetchData();
      }, [roleId, userId, groupMembers]);

  useEffect(() => {
    let filtered = data;

    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }

    if (startDate) {
      filtered = filtered.filter(
        (item) => new Date(item.CreatedDate) >= new Date(startDate)
      );
    }

    if (endDate) {
      filtered = filtered.filter(
        (item) => new Date(item.CreatedDate) <= new Date(endDate)
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  return (
    <div style={{ position: "relative" }}>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : filteredData.length > 0 ? (
        <DataTable
          columns={columns}
          data={filteredData || []}
          pagination
          striped
          persistTableHead
        />
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          No data available.
        </div>
      )}
    </div>
  );
};

export default InActiveIBReport;
