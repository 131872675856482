import React from 'react';
import { Card, CardHeader, CardBody, Col } from 'reactstrap';
import UpdateAgreement from '../../Pages/ClientDetail/Agreements/EditAgreementByID';

import { getMenuPerm } from 'utils';
const Agreements = ({ agreement = {}, userProfile, customerId, loadData, permissions, data, ibType }) => {
    // console.log("userProfile is: ", userProfile.entiry);
    const signupLink = `https://signup.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
    const demoLink = `https://signup-demo.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
    const partnerLink = `https://signup-partner.godocm.com?ptr=${userProfile.id}&rm=${userProfile.agentId}${(userProfile && userProfile.entity === 'FSA_MA') ? '&entity=FSA_MA&utm_medium=godoweb_ma' : ''}`;
    return (<React.Fragment>
        {agreement?.map((agreement, agreementIndex) => {
            return (
                <React.Fragment key={agreementIndex}>
                    {/* Card for each Agreement */}
                    <Col sm={6}>
                        <Card>
                            {/* Display the title of each agreement in the CardHeader */}
                            <CardHeader className='p-3'>
                                {agreement?.title}
                                {/* &nbsp;&nbsp;&nbsp;
                                    {getMenuPerm(permissions, 'ibs:update') === 1 && (
                                        <UpdateAgreement
                                        customerId={customerId}
                                        loadData={loadData}
                                        existingData={data}
                                        agreementId={agreement?.id}
                                        />
                                    )} */}
                            </CardHeader>

                            <CardBody>
                                {agreement?.members?.map((member, memIndex) => {
                                    return (
                                        <React.Fragment key={memIndex}>
                                            <p>Partner - {member?.Member?.firstName}</p>
                                            {member?.AccountTypeValues?.map((accType, acctypeInd) => {
                                                if (ibType === 'NTP') {
                                                    // Render only accountTypeId 14 for NTP
                                                    if (accType?.accountTypeId === 14) {
                                                        return (
                                                            <React.Fragment key={acctypeInd}>
                                                                <p className="mb-0">NTP Deal is - {accType?.rebate}</p>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                } else {
                                                    // Render accountTypeId 14 or 2 for other ibType values
                                                    if (accType?.accountTypeId === 14 || accType?.accountTypeId === 2) {
                                                        return (
                                                            <React.Fragment key={acctypeInd}>
                                                                <p className="mb-0">{accType?.AccountType?.title} - {accType?.rebate}</p>
                                                            </React.Fragment>
                                                        );
                                                    }
                                                }

                                                // Return null if no condition is met
                                                return null;
                                            })}
                                            <hr />
                                        </React.Fragment>
                                    );
                                })}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col sm={6}>
                        <Card>
                            <CardHeader className='p-3'>Dedicated Links</CardHeader>
                            <CardBody>
                                <p className='mb-0 ml-2'>
                                    Register Demo &nbsp;{' '}
                                    <a className='pl-3' href={demoLink + `&agr=${agreement?.id}`}>
                                        Demo
                                    </a>
                                </p>
                                <p className='mb-0 ml-2'>
                                    Register Live &nbsp;&nbsp;&nbsp;{' '}
                                    <a className='pl-3' href={signupLink + `&agr=${agreement?.id}`}>
                                        Live
                                    </a>
                                </p>
                                <p className='mb-0 ml-2'>
                                    Register Sub Partner
                                    <a className='pl-3' href={partnerLink + `&agr=${agreement?.id}`}>
                                        Sub Partner
                                    </a>
                                </p>
                            </CardBody>
                        </Card>
                    </Col>
                </React.Fragment>
            );
        })}

    </React.Fragment>)
};
export default Agreements;