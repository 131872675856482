import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import './ExtensionNumber.css';
import DataTable from 'react-data-table-component';
import { FaArrowRight, FaArrowDownLong } from "react-icons/fa6";
import { userService } from 'Services';

const OtherClients = () => {
    const [rec, setRec] = useState([]);
    const [department, setDepartment] = useState([]);
    const [expandedDepartment, setExpandedDepartment] = useState(null);

    useEffect(() => { loadData(); }, []);

    const loadData = (department) => {
        console.log('work');
        if (department) { // Checks if department is not an empty string, undefined, or null
            userService.getUsers({ limit: 100, department: department }).then((res) => {
                const sortedData = res.data.sort((a, b) => a.firstName.localeCompare(b.firstName));
                setRec(sortedData);
            });
        } else {
            userService.getUsers({ limit: 100 }).then((res) => {
                // Create a map to store unique users by department
                const uniqueDepartments = new Map();

                res.data.forEach((item) => {
                    if (item.department && !uniqueDepartments.has(item.department)) {
                        uniqueDepartments.set(item.department, item);
                    }
                });

                // Convert the map values to an array and sort by firstName
                const filteredData = Array.from(uniqueDepartments.values())
                    .sort((a, b) => a.firstName.localeCompare(b.firstName)); // Sort by firstName

                // Set the filtered data
                setDepartment(filteredData);
            });
        }
    };

    console.log('userList: ', rec);

    const handleExpand = (department) => {
        if (expandedDepartment === department) {
            // Collapse if the same department is clicked
            setExpandedDepartment(null);
        } else {
            // Expand and set the current department
            setExpandedDepartment(department);
            loadData(department);
        }
    };

    const tableColumns = [
        {
            name: 'Staff Name',
            sortable: true,
            center: true,
            cell: (item) => <div>{`${item.firstName} ${item.lastName || ''}`.trim()}</div>,
        },
        {
            name: 'Position',
            sortable: false,
            center: true,
            cell: (item) => <div>{item.position}</div>,
        },
        {
            name: 'Extension Number',
            sortable: false,
            center: true,
            cell: (item) => <div>{item.extention}</div>, // Corrected spelling from 'extention' to 'extension'
        },
    ];

    return (
        <Fragment>
            <Col sm='12' className='p-4'>
                <Card>
                    <div className='containerBody my-4'>
                        <h1 className='text-center fs-4'>Extension</h1>
                        <div className='table-responsive'>
                            <Container fluid={true} className='datatables'>
                                <Row>
                                    <Col sm='12'>
                                        <Card>
                                            <CardBody>
                                                {department.map((item, index) => (
                                                    <Fragment key={index}>
                                                        <Card onClick={() => handleExpand(item.department)} className="my-4">
                                                            <div style={{ height: '65px', backgroundColor: '#194995' }} className='d-flex align-items-center px-3 justify-content-between'>
                                                                <h6 className='text-white'>{item.department}</h6>
                                                                {expandedDepartment !== item.department
                                                                    ? <p className='text-white fs-6'>{item.deptExtention} <FaArrowRight fill='white' className='fs-6 ps-2 pointer' /></p>
                                                                    : <p className='text-white fs-6'>{item.deptExtention} <FaArrowDownLong fill='white' className='fs-6 ps-2 pointer' /></p>
                                                                }
                                                            </div>
                                                        </Card>
                                                        {expandedDepartment === item.department && (
                                                            <DataTable
                                                                data={rec}
                                                                columns={tableColumns}
                                                                striped={true}
                                                                center={true}
                                                                selectableRows
                                                                persistTableHead
                                                            />
                                                        )}
                                                    </Fragment>
                                                ))}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </Card>
            </Col>
        </Fragment>
    );
};

export default OtherClients;
