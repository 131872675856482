import api from './api';

export const UsersTimeOff = () => {
    return api.get('/v1/crm/UsersTimeOff');
};

export const createUsersTimeOff = (data) => {
    return api.post('/v1/crm/UsersTimeOff', data);
};

export const updateUsersTimeOff= (id, data) => {
    return api.put(`/v1/crm/UsersTimeOff/${id}`, data);
};

export const deleteUsersTimeOff = (id) => {
    return api.delete(`/v1/crm/UsersTimeOff/${id}`);
};
