import api from "./api";

// Function to get all documents from the backend
export const fetchDocuments = async () => {
  try {
    const response = await api.get(`/v1/crm/Managedocs`);
    return response.data; // Return the documents data
  } catch (error) {
    console.error('Error fetching documents:', error.response.data || error.message);
    throw error;
  }
};

// Function to create a new document with file upload
export const createDocument = async (formData) => {
  console.log("formdata: ", formData);
  try {
    const response = await api.post(`/v1/crm/Managedocs`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    console.log('data: ', response);
    return response; // Return the created document data
  } catch (error) {
    console.error('Error creating document:', error.response.data || error.message);
    throw error;
  }
};

export const getDocumentSas = (id)=> {
  //return api.get('/v1/crm/documents/'+id, '?fileNumber='+number)
  return api.get(`/v1/crm/Managedocs/${id}`)
}

// export const addRecord = async (recordData) => {
//   console.log("Record data: ", recordData);
//   try {
//     const response = await api.post(`/v1/crm/Managedocs`, recordData, {
//       headers: {
//         'Content-Type': 'application/json',
//       },
//     });
    
//     console.log('Record created: ', response);
//     return response.data; // Return the newly created record data
//   } catch (error) {
//     console.error('Error adding record:', error.response.data || error.message);
//     throw error;
//   }
// };
// Function to update an existing document
export const updateDocument = async (id, updatedData) => {
  try {
    const response = await api.put(`/v1/crm/Managedocs/${id}`, updatedData);
    return response.data; // Return the updated document data
  } catch (error) {
    console.error('Error updating document:', error.response.data || error.message);
    throw error;
  }
};

// Function to delete a document
export const deleteDocument = async (id) => {
  try {
    await api.delete(`/v1/crm/Managedocs/${id}`);
  } catch (error) {
    console.error('Error deleting document:', error.response.data || error.message);
    throw error;
  }
};
