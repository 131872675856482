import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Table,
    Button,
    Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
} from 'reactstrap';
import { fetchemployeeS, createemployeeS, updateemployeeS, deleteemployeeS } from 'Services/employeeS.service';
import { getUserOptions } from 'Services/user.service';

const EmployeeSalaries = () => {
    const [employeeSModal, setemployeeSModal] = useState(false);
    const [employeeSearch, setemployeeSearch] = useState('');
    const [employeeS, setemployeeS] = useState([]);
    const [userOptions, setUserOptions] = useState([]); // Store user options
    const [employeeSformvalues, setemployeeSformvalues] = useState([{
        empName: '',
        empNo: '',
        totalS: '',
        basicS: '',
        accommodation: '',
        transportation: ''
    }]);
    const [isEditMode, setIsEditMode] = useState(false);

    const loadData = async () => {
        try {
            const data = await fetchemployeeS();
            console.log('DATA...', data)
            if (data && Array.isArray(data.rows)) {
                setemployeeS(data.rows);
            } else {
                console.error('Expected an array in rows, but got:', data);
            }
        } catch (error) {
            console.error('Error fetching and filtering emps:', error);
        }
    };

    const loadUserOptions = async () => {
        try {
            const response = await getUserOptions();
            console.log('API Response:', response);

            if (!response || !response.data || !Array.isArray(response.data.data)) {
                console.error('No valid data in response:', response);
                return;
            }

            const userDataArray = response.data.data;
            console.log('User Data Array:', userDataArray);

            const formattedOptions = userDataArray.map(user => {
                if (user.id && user.firstName && user.lastName) {
                    return {
                        value: user.id,
                        label: `${user.firstName} ${user.lastName}`
                    };
                } else {
                    console.error('User object is missing properties:', user);
                    return null;
                }
            }).filter(option => option !== null);

            console.log('Formatted Options:', formattedOptions);
            setUserOptions(formattedOptions);
        } catch (error) {
            console.error('Failed to fetch employees:', error);
        }
    };

    useEffect(() => {
        loadData();
        loadUserOptions(); // Fetch users on component load
    }, []);

    const toggleModal = () => setemployeeSModal(!employeeSModal);

    const calculateSalaries = (totalSalary) => {
        const total = parseFloat(totalSalary) || 0;
        return {
            basicS: (total * 0.6).toFixed(2),
            accommodation: (total * 0.25).toFixed(2),
            transportation: (total * 0.15).toFixed(2),
        };
    };

    const handleFormChange = (e, id) => {
        const { name, value } = e.target;
        const updatedData = [...employeeSformvalues];

        if (name === 'empName') {
            const selectedEmployee = userOptions.find(emp => `${emp.label}` === value);

            updatedData[id] = {
                ...updatedData[id],
                empName: value,
                empNo: selectedEmployee ? selectedEmployee.value : '' // Auto-fill employee number
            };
        } else {
            updatedData[id] = { ...updatedData[id], [name]: value };

            // If the total salary changes, calculate the basic, accommodation, and transportation values
            if (name === 'totalS' && value) {
                const calculatedSalaries = calculateSalaries(value); // Function that returns calculated salary components
                updatedData[id] = {
                    ...updatedData[id],
                    basicS: calculatedSalaries.basicS,
                    accommodation: calculatedSalaries.accommodation,
                    transportation: calculatedSalaries.transportation,
                };
            }
        }

        setemployeeSformvalues(updatedData);
    };

    const initializeCreateForm = () => {
        setIsEditMode(false);
        setemployeeSformvalues([{
            empName: '',
            empNo: '',
            totalS: '',
            basicS: '',
            accommodation: '',
            transportation: ''
        }]);
        toggleModal();
    };

    const initializeUpdateForm = (employeeS) => {
        setemployeeSformvalues([employeeS]);
        setIsEditMode(true);
        toggleModal();
    };

    const deleteEmpS = async (id) => {
        try {
            await deleteemployeeS(id);
            toast.success('Salary Deleted Successfully!');
            setemployeeS((prevDetails) => prevDetails.filter((employeeS) => employeeS.id !== id));
        } catch (error) {
            console.error('Error deleting salary:', error);
            toast.error('Failed to delete salary. Please try again.');
        }
    };

    const handleSavedata = async (e) => {
        e.preventDefault();
        try {
            if (isEditMode) {
                await updateemployeeS(employeeSformvalues[0].id, employeeSformvalues[0]);
                toast.success('Salary updated successfully!');
            } else {
                console.log('formData', employeeSformvalues[0]);
                await createemployeeS(employeeSformvalues[0]);
                toast.success('Salary added successfully!');
            }
            await loadData();
            toggleModal();
        } catch (error) {
            console.error('Error saving salary:', error);
            toast.error('Failed to save salary. Please try again.');
        }
    };

    const handleSearchChange = (e) => {
        setemployeeSearch(e.target.value);
    };

    const filteredData = employeeS.filter((employeeS) =>
        employeeS.empName.toLowerCase().includes(employeeSearch.toLowerCase()) ||
        employeeS.empNo.toLowerCase().includes(employeeSearch.toLowerCase())
    );

    const renderModal = () => (
        <Modal isOpen={employeeSModal} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
                {isEditMode ? 'Edit Salary Info' : 'Add Salary Info'}
            </ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSavedata}>
                    {employeeSformvalues.map((employeeS, index) => (
                        <div key={employeeS.id || index}>
                            <FormGroup>
                                <Label for="empName">Employee Name</Label>
                                <Input
                                    type="select"
                                    name="empName"
                                    id="empName"
                                    value={employeeSformvalues[0].empName}
                                    onChange={(e) => handleFormChange(e, 0)}
                                >
                                    <option value="">Select Employee</option>
                                    {userOptions.map(user => (
                                        <option key={user.value} value={user.label}>
                                            {user.label}
                                        </option>
                                    ))}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="empNo">Employee Number</Label>
                                <Input
                                    type="text"
                                    name="empNo"
                                    id="empNo"
                                    value={employeeSformvalues[0].empNo}
                                    onChange={(e) => handleFormChange(e, 0)}
                                    disabled
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for={`totalS_${index}`}>Total Salary</Label>
                                <Input
                                    type="text"
                                    name="totalS"
                                    id={`totalS_${index}`}
                                    value={employeeS.totalS || ''}
                                    onChange={(e) => handleFormChange(e, index)}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for={`basicS_${index}`}>Basic Salary</Label>
                                <Input
                                    type="text"
                                    name="basicS"
                                    id={`basicS_${index}`}
                                    value={employeeS.basicS || ''}
                                    onChange={(e) => handleFormChange(e, index)}
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for={`accommodation_${index}`}>Accommodation</Label>
                                <Input
                                    type="text"
                                    name="accommodation"
                                    id={`accommodation_${index}`}
                                    value={employeeS.accommodation || ''}
                                    onChange={(e) => handleFormChange(e, index)}
                                    required
                                />
                            </FormGroup>

                            <FormGroup>
                                <Label for={`transportation_${index}`}>Transportation</Label>
                                <Input
                                    type="textarea"
                                    name="transportation"
                                    id={`transportation_${index}`}
                                    value={employeeS.transportation || ''}
                                    onChange={(e) => handleFormChange(e, index)}
                                />
                            </FormGroup>
                        </div>
                    ))}
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {isEditMode ? 'Update' : 'Save'}
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Form>
            </ModalBody>
        </Modal>
    );

    return (
        <>
            <Card>
                <CardHeader>
                    <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                        Employee Salaries
                    </h3>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Input
                            type="text"
                            placeholder="Search by Employee Name or Number"
                            value={employeeSearch}
                            onChange={handleSearchChange}
                            style={{ width: '300px', marginBottom: '20px' }}
                        />
                          <style>
  {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
</style>
                        <Button color="primary" onClick={initializeCreateForm}>
                            Add
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table striped>
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>Employee Number</th>
                                <th>Total Salary</th>
                                <th>Basic Salary</th>
                                <th>Accommodation</th>
                                <th>Transportation</th>
                                <th>Edit</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.length > 0 ? (
                                filteredData.map((employeeS) => (
                                    <tr key={employeeS.id}>
                                        <td>{employeeS.empName}</td>
                                        <td>{employeeS.empNo}</td>
                                        <td>{employeeS.totalS}</td>
                                        <td>{employeeS.basicS}</td>
                                        <td>{employeeS.accommodation}</td>
                                        <td>{employeeS.transportation}</td>
                                        <td>
                                            <Button color="primary" onClick={() => initializeUpdateForm(employeeS)}>
                                                Edit
                                            </Button>
                                        </td>
                                        <td>
                                            <Button color="danger" onClick={() => deleteEmpS(employeeS.id)}>
                                                Delete
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">No Records found</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            {renderModal()}
        </>
    );
};

export default EmployeeSalaries;
