import api from "./api";
var queryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const getUsers = (params) => {
  console.log("Params are ",params);
  return api.get("/v1/crm/users/?" + queryString(params));
};

export const getUser = (params) => {
  console.log("Params are ",params);
  return api.get("/v1/crm/users/" +params);
};

export const delUsers = (id) => {
  return api.delete("/v1/crm/users/" + id);
};
// export default new UserService();
export const getUsersDropdown = (query = "") => {
  return api.get("/v1/crm/users/dropdown?query=" + query);
};
export const addUser = (params) => {
  return api.post("/v1/crm/users/", params);
};
export const updateUser = (params, id) => {
  return api.patch("/v1/crm/users/" + id, params);
};
/*export const getAccounts = (params) => {
  console.log('hmm');
  return api.get("/v1/crm/accounts/", params);
};*/

//HRMS.............................
export const getUserOptions  = () => {
  return api.get('./v1/crm/users/getoptions');
};