import api from './api';

export const VacationDays = () => {
    return api.get('/v1/crm/VacationDays');
};

export const addVacationDays = (data) => {
    return api.post('/v1/crm/VacationDays', data);
};

export const updateVacationDays = (id, data) => {
    return api.put(`/v1/crm/VacationDays/${id}`, data);
};

export const deleteVacationDays = (id) => {
    return api.delete(`/v1/crm/VacationDays/${id}`);
};

