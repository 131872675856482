import api from './api'; // Assuming `api` is your axios instance

// Fetch Not Contacted Leads
export const getNotContactedLeads = async () => {
    return api.get('/v1/crm/SalesReport/not-contacted-leads');
};

// Fetch Organic Leads
export const getOrganicLeads = async () => {
    return api.get('/v1/crm/SalesReport/organic-leads');
};

// Fetch My Deposits
export const getMyDeposits = async () => {
    return api.get('/v1/crm/SalesReport/my-deposits');
};

// Fetch My Withdrawals
export const getMyWithdrawals = async () => {
    return api.get('/v1/crm/SalesReport/my-withdrawals');
};

// Fetch My ActiveIB
export const getActiveIB = async () => {
    return api.get('/v1/crm/SalesReport/Active-IB');
};

// Fetch My InActiveIB
export const getInActiveIB = async () => {
    return api.get('/v1/crm/SalesReport/InActive-IB');
};

// Fetch Group Members by ID
export const getGroupMembersById = async (groupId) => {
    return api.get(`v1/crm/SalesReport/group-members/${groupId}`);
};