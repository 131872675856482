import api from './api';

export const getEmails = () => {
    return api.get('v1/crm/email');
}


export const getDocumentSas = (id, number = 1)=> {
    console.log(number, 'fileNumber is');
    //return api.get('/v1/crm/documents/'+id, '?fileNumber='+number)
    return api.get(`/v1/crm/email/${id}?fileNumber=${number}`)
}
