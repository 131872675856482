import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row } from 'reactstrap';
import { clientService } from 'Services';
import Spinner from 'CommonElements/Spinner';
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ClientDetailsHeader from './ClientDetailHeader';
import ClientDetailFooter from './ClientDetailFooter';
import { useParams, Link, Await } from 'react-router-dom';
import ClientProfile from './ClientProfile/ClientProfile';
import BankAccounts from './BankAccounts/BankAccounts';
import Documents from './Documents/Documents';
import Accounts from './Accounts/Accounts';
import Transactions from './Transactions/Transactions';
import Notes from './Notes/Index';
import Logs from './Logs/Logs';
import Tickets from "./Tickets/Tickets";
import { Btn } from 'AbstractElements';
//import BackButton from 'Components/Common/Component/backButton';
//import {  Label, Input } from 'reactstrap';
import Agreements from './Agreements/Agreements';
import Statement from './Statement/Statement';
import NTPStatement from './Statement/StatementNTP';
import IbClients from './IbClients/IbClients';
import { useSelector } from "react-redux";
import { getMenuPerm } from 'utils';
import { toast } from "react-toastify";


const ContactFirebase = (props) => {
  const [rec, setRec] = useState({});
  const pathParams = useParams();
  const customerId = pathParams.id;

  const permissions = useSelector(
    (state) => (state.login && state.login.profile && state.login.profile.Role && state.login.profile.Role.permissions) || {}
  );

  useEffect(() => {
    if (pathParams.id) {
      setRec({
        loading: true,
        data: [],
      });
      clientService
        .getClientDetail(pathParams.id)
        .then((res) => {
          //console.log('Client Data is ',rec);
          setRec({
            ...res,
            loading: false,
          });
          setActiveTab(0);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  // code for tabs start
  const [activeTab, setActiveTab] = useState();
  const pathParam = window.location.pathname;
  const shouldShowLeadsTabs = pathParam && pathParam.includes('leads');
  const shouldShowIbTabs = pathParam && pathParam.includes('ibs');
  const tabsArr = shouldShowLeadsTabs ?
    [
      { component: ClientProfile, title: 'Profile', icon: 'icofont-man-in-glasses' },
      { component: Tickets, title: 'Tickets', icon: '' },
      { component: Notes, title: 'Tasks', icon: 'icofont-newspaper' },
      { component: Logs, title: 'Logs', icon: '' },
    ] : shouldShowIbTabs ?
      [
        { component: ClientProfile, title: 'Profile', icon: 'icofont-man-in-glasses' },
        { component: Accounts, title: 'Accounts', icon: 'icofont-contacts' },
        { component: Documents, title: 'Documents', icon: 'icofont-contacts' },
        { component: BankAccounts, title: 'Banks & Wallets', icon: 'icofont-ui-home' },
        { component: Transactions, title: 'Transactions', icon: '' },
        { component: Agreements, title: 'Commission', icon: 'icofont-newspaper' },
        (
          rec && rec.ibType == 'NTP' ?
            { component: NTPStatement, title: 'Statement', icon: 'icofont-newspaper' } :
            { component: Statement, title: 'Statement', icon: 'icofont-newspaper' }
        ),
        { component: IbClients, title: 'Clients', icon: 'icofont-newspaper' },
        // { component: IbClientsAccount, title: 'Clients Account', icon: 'icofont-newspaper' },
        { component: Tickets, title: 'Tickets', icon: '' },
        { component: Notes, title: 'Tasks', icon: 'icofont-newspaper' },
        { component: Logs, title: 'Logs', icon: '' },
      ] :
      [
        getMenuPerm(permissions, 'clientDetails:profile') === 1 && {
          component: ClientProfile,
          title: 'Profile',
          icon: 'icofont-man-in-glasses',
        },
        getMenuPerm(permissions, 'clientDetails:accounts') === 1 && {
          component: Accounts,
          title: 'Accounts',
          icon: 'icofont-contacts',
        },
        getMenuPerm(permissions, 'clientDetails:documents') === 1 && {
          component: Documents,
          title: 'Documents',
          icon: 'icofont-contacts',
        },
        getMenuPerm(permissions, 'clientDetails:bank') === 1 && {
          component: BankAccounts,
          title: 'Banks & Wallets',
          icon: 'icofont-ui-home',
        },
        getMenuPerm(permissions, 'clientDetails:transactions') === 1 && {
          component: Transactions,
          title: 'Transactions',
          icon: '',
        },
        getMenuPerm(permissions, 'clientDetails:tickets') === 1 && {
          component: Tickets,
          title: 'Tickets',
          icon: '',
        },
        getMenuPerm(permissions, 'clientDetails:tasks') === 1 && {
          component: Notes,
          title: 'Tasks',
          icon: 'icofont-newspaper',
        },
        getMenuPerm(permissions, 'clientDetails:logs') === 1 && {
          component: Logs,
          title: 'Logs',
          icon: '',
        },
      ].filter(Boolean);

  // const arr = [
  //   {key: 'Is Funded', value: rec && rec.isFunded},
  //   // {key: 'Email Verified', value: data.emailVerified},
  //   // {key: 'Phone Verified', value: data.phoneVerified},
  //   {key: 'Is Active', value: rec && rec.isActive},
  //   {key: 'Is Blocked', value: rec && rec.isBlocked},
  //   {key: 'Is Client', value: rec && rec.isClient},
  //   {key: 'Is Ib', value: rec && rec.isIb},
  //   {key: 'Is Lead', value: rec && rec.isLead},
  // ];

  return (
    <Fragment>
      <Container fluid={true}>
        {rec.loading && <Spinner />}
        {!rec.loading && (
          <Row>
            <Col sm={'12'}>
              <div className='user-profile mt-4'>
                <ClientDetailsHeader customerId={customerId} data={rec} />
              </div>
              <Link to="/tickets" style={{ right: '10px', position: 'absolute' }}>
                <Btn
                  attrBtn={{ color: "primary" }}
                >
                  Create Ticket
                </Btn>
              </Link>
              <Nav tabs className='mb-4' style={{ maxWidth: '75%' }}>
                {tabsArr.map((tab, index) => (
                  <NavItem key={index}>
                    <NavLink
                      href='#'
                      className={activeTab === index ? 'active' : ''}
                      onClick={() => setActiveTab(index)}
                    >
                      <i className={`icofont ${tab.icon}`}></i>
                      {tab.title}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
              <TabContent activeTab={activeTab}>
                {tabsArr.map((tab, index) => (
                  <TabPane key={index} className='fade show' tabId={index}>
                    <tab.component
                      isActive={activeTab === index}
                      customerId={customerId}
                      data={rec}
                      activeTab={activeTab}
                      tabNumber={index}
                    />
                  </TabPane>
                ))}
              </TabContent>

              <div className='user-profile mt-4'>
                <ClientDetailFooter customerId={customerId} data={rec} />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Fragment>
  );
};
export default ContactFirebase;
