import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { SalesReportService } from "Services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Doughnut, Bar } from "react-chartjs-2"; // Import Bar chart from react-chartjs-2
import { Chart as ChartJS, ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";
import { Grid } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

const NotContactedLeadsReport = ({ searchTerm, startDate, endDate, showCharts }) => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [groupMembers, setGroupMembers] = useState([]); // State for group members

  // Get the role ID and user ID from Redux
  const profile = useSelector((state) => state.login?.profile);
  const roleId = profile?.roleId || null;
  const userId = profile?.id || null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB"); // Format: DD/MM/YYYY
  };

  const columns = [
    {
      name: "Created Date",
      selector: (row) => formatDate(row.CreatedDate),
      sortable: true,
    },
    { name: "IB", selector: (row) => row.IB, sortable: true },
    { name: "Sales", selector: (row) => row.Sales, sortable: true },
    { name: "Name", selector: (row) => row.Name, sortable: true },
    { name: "Country", selector: (row) => row.Country, sortable: true },
    { name: "Status", selector: (row) => row.Status, sortable: true },
    { name: "Days", selector: (row) => row.Days, sortable: true },
  ];

  // Fetch group members by userId
  useEffect(() => {
    if (userId) {
      const fetchGroupMembers = async () => {
        try {
          const response = await SalesReportService.getGroupMembersById(userId);
          setGroupMembers(response); // Store the group members
        } catch (err) {
          console.error("Failed to fetch group members:", err);
          toast.error("Failed to fetch group members.");
          setGroupMembers([]); // Reset group members if the API fails
        }
      };

      fetchGroupMembers();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await SalesReportService.getNotContactedLeads();

        // Filter data based on role and group members
        let filteredResponse = response;

        // If the role is not in the specified ones and groupMembers is not empty, filter by group members
        if (roleId !== 33 && roleId !== 18 && roleId !== 41) {
          if (groupMembers.length > 0) {
            // Filter deposits by matching agentId with group members
            filteredResponse = response.filter((item) =>
              groupMembers.includes(item.agentId)
            );
          } else {
            // If no group members are found, filter by userId
            filteredResponse = response.filter((item) => item.agentId === userId);
          }
        }

        setData(filteredResponse);
        setFilteredData(filteredResponse);
      } catch (err) {
        setError("Failed to fetch data.");
        toast.error("Failed to fetch data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [roleId, userId, groupMembers]);

  useEffect(() => {
    let filtered = data;

    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filtered = filtered.filter((item) =>
        Object.values(item).some(
          (value) =>
            value && value.toString().toLowerCase().includes(lowercasedTerm)
        )
      );
    }

    if (startDate) {
      filtered = filtered.filter(
        (item) =>
          new Date(item.CreatedDate) >= new Date(startDate)
      );
    }

    if (endDate) {
      filtered = filtered.filter(
        (item) =>
          new Date(item.CreatedDate) <= new Date(endDate)
      );
    }

    setFilteredData(filtered);
  }, [searchTerm, startDate, endDate, data]);

  // Prepare the data for the Doughnut Chart
  const getDoughnutChartData = () => {
    const statusCounts = filteredData.reduce(
      (acc, item) => {
        acc[item.Status] = (acc[item.Status] || 0) + 1;
        return acc;
      },
      {}
    );

    return {
      labels: Object.keys(statusCounts),
      datasets: [
        {
          data: Object.values(statusCounts),
          backgroundColor: ["#3c9dd0", "#ff7954", "#631E50"], // Customize colors
          hoverBackgroundColor: ["#3c9dd0", "#ff7954", "#631E50"],
        },
      ],
    };
  };

  // Prepare the data for the Bar Chart
  const getBarChartData = () => {
    // Filter out "Direct" entries from the data
    const filteredIBCounts = filteredData.reduce((acc, item) => {
      if (item.IB !== "Direct") {
        acc[item.IB] = (acc[item.IB] || 0) + 1;
      }
      return acc;
    }, {});
  
    return {
      labels: Object.keys(filteredIBCounts),
      datasets: [
        {
          label: "IB Count",
          data: Object.values(filteredIBCounts),
          backgroundColor: "#086CA2", // Bar color
          borderColor: "#086CA2", // Border color
          borderWidth: 1,
        },
      ],
    };
  };
  
  return (
    <div style={{ position: "relative" }}>
      {error && <p className="text-danger">{error}</p>}
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      ) : filteredData.length > 0 ? (
        <Grid container spacing={2}>
          {/* Conditionally render the Doughnut chart and Bar chart based on showCharts */}
          {showCharts && (
            <>
              <Grid item xs={12} md={3}>
                <div style={{ marginBottom: "20px", width: "100%" }}>
                  <Doughnut data={getDoughnutChartData()} />
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <div style={{ marginBottom: "20px", width: "100%" }}>
                  <Bar data={getBarChartData()} options={{ responsive: true }} />
                </div>
              </Grid>
            </>
          )}
          {/* DataTable */}
          <Grid item xs={12} md={12}>
            <DataTable
              columns={columns}
              data={filteredData || []}
              pagination
              striped
              persistTableHead
            />
          </Grid>
        </Grid>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
            color: "#007bff",
          }}
        >
          No data available.
        </div>
      )}
    </div>
  );
};

export default NotContactedLeadsReport;
