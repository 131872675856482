import api from './api';

export const fetchemployeeS = () => {
    return api.get(`/v1/crm/employeeS`);
};

export const createemployeeS = (data) => {
    console.log('Data....', data)
    return api.post(`/v1/crm/employeeS`, data);
};

export const updateemployeeS= (id, data) => {
    return api.put(`/v1/crm/employeeS/${id}`, data);
};

export const deleteemployeeS = (id) => {
    return api.delete(`/v1/crm/employeeS/${id}`);
};
    