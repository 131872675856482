import React, { useState, useEffect, Fragment } from 'react';
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
} from 'reactstrap';
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './AdminDashboard.css';
import { UsersPersonalDetail } from 'Services/usersPersonalDetail.service';
import { VacationDays } from 'Services/vacationdays.service';
import { UsersTimeOff } from 'Services/usersTimeoff.service';
import { fetchDocuments } from 'Services/managedocs.service';

const HrmsAdminDashboard = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState(false);
  const [formValues, setFormValues] = useState({
    eventTitle: '',
    eventPriority: 'normal',
    eventDate: new Date().toISOString().substr(0, 10),
  });
  
  const [today] = useState(new Date());
  const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);



  const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);
  console.log("upcom.....................", upcomingBirthdays);

  useEffect(() => {
    const fetchBirthdays = async () => {
      try {
        const allUsers = await UsersPersonalDetail(); // Fetch all user details
        console.log('users', allUsers);
  
        const today = new Date();
        const threeWeeksFromNow = new Date();
        threeWeeksFromNow.setDate(today.getDate() + 21);
  
        const upcoming = allUsers
          .map(user => {
            const birthDate = new Date(user.birth_date); // Parse birth_date
            if (isNaN(birthDate)) {
              console.error(`Invalid birth_date for user:`, user);
              return null;
            }
  
            // Create a birthday date for this year
            let birthDateThisYear = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());
  
            // If the birthday this year has already passed, adjust to next year
            if (birthDateThisYear < today) {
              birthDateThisYear.setFullYear(today.getFullYear() + 1);
            }
  
            // Calculate days until the birthday
            const timeDiff = Math.ceil((birthDateThisYear - today) / (1000 * 60 * 60 * 24));
  
            return { 
              ...user, 
              timeDiff, 
              formattedBirthDate: birthDateThisYear.toLocaleDateString(), 
              birthDateThisYear 
            };
          })
          .filter(user => {
            if (!user) return false;
  
            // Ensure the birthday falls within the next 3 weeks
            return user.birthDateThisYear >= today && user.birthDateThisYear <= threeWeeksFromNow;
          })
          .sort((a, b) => a.timeDiff - b.timeDiff);
  
        setUpcomingBirthdays(upcoming);
        console.log("upcoming", upcoming);
      } catch (error) {
        console.error('Error fetching upcoming birthdays:', error);
      }
    };
  
    fetchBirthdays();
  }, []);
  



  const formatTimeLeft = (daysLeft) => {
    const weeks = Math.floor(daysLeft / 7);
    const days = daysLeft % 7;
    if (weeks > 0 && days > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} and ${days} day${days > 1 ? 's' : ''} left`;
    } else if (weeks > 0) {
      return `${weeks} week${weeks > 1 ? 's' : ''} left`;
    } else {
      return `${days} day${days > 1 ? 's' : ''} left`;
    }
  };

  const toggle = () => setModal(!modal);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = () => {
    if (formValues.eventTitle) {
      setEvents([
        ...events,
        {
          date: new Date(formValues.eventDate),
          title: formValues.eventTitle,
          priority: formValues.eventPriority,
        },
      ]);
      toggle();
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const getEventColor = (priority) => {
    switch (priority) {
      case 'low':
        return 'green';
      case 'high':
        return 'red';
      default:
        return 'blue';
    }
  };

  const [absent, setAbsent] = useState([]);
  console.log("Absentee", absent);
  useEffect(() => {
    const fetchAbsentees = async () => {
      try {
        const absentees = await VacationDays(); // Fetch all user details
        console.log('Absentee Today', absentees);

        const today = new Date();

        // Filter absentees whose vacation period includes today
        const currentlyAbsent = absentees.filter((absentees) => {
          const fromDate = new Date(absentees.fromDate);
          const toDate = new Date(absentees.toDate);

          // Check if today falls between fromDate and toDate (inclusive)
          return today >= fromDate && today <= toDate;
        });
        console.log("result", currentlyAbsent);
        setAbsent(currentlyAbsent);
      } catch (error) {
        console.error("Error fetching absentees", error);
      }
    };

    fetchAbsentees();
  }, []);

 

  const handleDateSelection = (date) => {
    setSelectedDate(date); // Update state with the selected date
  };

  const [expiry, setExpiry] = useState([]);
  console.log("Expiring..", expiry);
  useEffect(() => {
    const fetchedExpiry = async () => {
      try {
        const expiringSoon = await fetchDocuments();
        console.log("Expiring.....", expiringSoon);

        const today = new Date();

        const filteredExpiry = expiringSoon.filter((document) => {
          const expiryDate = new Date(document.expiryDate); // Replace `expiryDate` with the actual property name
          const docType = document.docType; // Replace `docType` with the actual property name

          let reminderPeriod;
          if (docType === "PASSPORT") {
            reminderPeriod = 6; // 6 months for PASSPORT
          } else if (docType === "VISA") {
            reminderPeriod = 1; // 1 month for VISA
          } else {
            return false; // Ignore other document types
          }

          const reminderDate = new Date(today);
          reminderDate.setMonth(today.getMonth() + reminderPeriod);

          return expiryDate >= today && expiryDate <= reminderDate;
        });

        setExpiry(filteredExpiry);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchedExpiry();
  }, []);

  

  return (
    <Fragment>
         <style>
        {`
          .form-group.w-75 {
            display: none;
       
          }
            .form-inline.search-fulls.col-7 {
              border: none;
            }
 
          .MuiBox-root.css-19midj6 {
                  padding: 3px;
            }
        `}
      </style>
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6">
              <h3></h3>
            </div>
            <div className="col-6">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <Row className="card-container">
        {/* Who’s Off / Working Remotely */}
        <Col sm="4" className="card-column">
          <Card className="card-common who-is-off">
            <CardHeader>
              <h5>Who’s Off / Working Remotely</h5>
            </CardHeader>
            <CardBody>
              {absent.length > 0 ? (
                <ul>
                  {absent.map((absentees, index) => (
                    <li key={index}>
                      {absentees.empName} - From: {new Date(absentees.fromDate).toLocaleDateString()} To: {new Date(absentees.toDate).toLocaleDateString()}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No one’s off!</p>
              )}
            </CardBody>
          </Card>
        </Col>


        {/* Documents Expiring */}
        {/* Documents Expiring */}
        <Col sm="4" className="card-column">
          <Card className="card-common documents-expiring-soon">
            <CardHeader>
              <h5>Documents Expiring Soon 📄</h5>
            </CardHeader>
            <CardBody>
              {expiry.length > 0 ? (
                <ul className="expiring-documents-list">
                  {expiry.map((expiringSoon, index) => (
                    <li key={index} className="expiring-document-item">
                      <span className="initial">{expiringSoon.owner.charAt(0)}</span>
                      <div className="document-info">
                        <p className="name-and-type">
                          {expiringSoon.owner} - {expiringSoon.docType}
                        </p>
                        <p className="expiry-date">
                          {new Date(expiringSoon.expiryDate).toLocaleDateString()}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="no-expiring-documents">
                  No documents expiring within the next six months!
                </p>
              )}
            </CardBody>
          </Card>
        </Col>




        {/* Announcements */}
        <Col sm="4" className="card-column">
          <Card className="card-common announcements">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5>Announcements</h5>
              <div>
                <Button color="link" className="p-0 anno">+ Add</Button>
                <Button color="link" className="p-0 anno">View All</Button>
              </div>
            </CardHeader>
            <CardBody>
              <p>No Announcements</p>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Upcoming Birthdays and Calendar */}
      <Row className="birthdays-and-calendar mt-4">
        {/* Upcoming Birthdays */}
        <Col sm="6">
          <Card className="card-common upcoming-birthdays">
            <CardHeader>
              <h5>Upcoming Birthdays 🎂</h5>
            </CardHeader>
            <CardBody>
              <ul>
                {upcomingBirthdays.length > 0 ? (
                  upcomingBirthdays.map((user, index) => (
                    <li key={index}>
                      <span className="initial">{user.name.charAt(0)}</span>
                      <div className="birthday-info">
                        <p className="name">{user.name}</p>
                        <p className="date">{new Date(user.birth_date).toLocaleDateString()}</p>
                      </div>
                      <p className="time-left">{formatTimeLeft(user.timeDiff)}</p>
                    </li>
                  ))
                ) : (
                  <p>No upcoming birthdays in the next 3 weeks</p>
                )}
              </ul>
            </CardBody>
          </Card>
        </Col>

        {/* Calendar */}
        <Col sm="6">
          <Card className="card-common calendar">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5>Calendar</h5>
              {/* <Button color="primary" onClick={toggle}>Add Event</Button> */}
            </CardHeader>
            <CardBody>
              <Calendar
                variant="default"
                font="Arial"
                onDateChange={handleDateChange}
                value={selectedDate}
                events={events.map((event) => ({
                  date: event.date,
                  title: event.title,
                  color: getEventColor(event.priority),
                }))}
                style={{ width: '100%' }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>

{/* 
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Add Event</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="eventTitle">Event Title</Label>
              <Input
                type="text"
                name="eventTitle"
                id="eventTitle"
                value={formValues.eventTitle}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="eventDate">Date</Label>
              <Input
                type="date"
                name="eventDate"
                id="eventDate"
                value={formValues.eventDate}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="eventPriority">Priority</Label>
              <Input
                type="select"
                name="eventPriority"
                id="eventPriority"
                value={formValues.eventPriority}
                onChange={handleChange}
              >
                <option value="low">Low</option>
                <option value="normal">Normal</option>
                <option value="high">High</option>
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Submit</Button>
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal> */}
    </Fragment>
  );
};

export default HrmsAdminDashboard;
