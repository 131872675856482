import React, { useState } from 'react';
import {
    Table,
    Button,
    Input,
    Card,
    CardBody,
    CardHeader,
    Form,
    FormGroup,
    Label,
    Alert,
} from 'reactstrap';

const GratuityCalculator = () => {
    const [firstWorkingDay, setFirstWorkingDay] = useState('');
    const [lastWorkingDay, setLastWorkingDay] = useState('');
    const [basicSalary, setBasicSalary] = useState('');
    const [gratuity, setGratuity] = useState(null);
    const [tenure, setTenure] = useState('');
    const [details, setDetails] = useState([]);
  
    const calculateGratuity = () => {
      if (!firstWorkingDay || !lastWorkingDay || !basicSalary) {
        alert('Please fill in all fields.');
        return;
      }
  
      const startDate = new Date(firstWorkingDay);
      const endDate = new Date(lastWorkingDay);
  
      if (endDate <= startDate) {
        alert('Last working day must be after the first working day.');
        return;
      }
  
      const basicSalaryPerDay = basicSalary / 30;
      const gratuityDetails = [];
      let totalGratuity = 0;
      let currentStart = new Date(startDate);
      let totalDays = 0;
  
      while (currentStart < endDate) {
        const nextYear = new Date(currentStart);
        nextYear.setFullYear(currentStart.getFullYear() + 1);
  
        const periodEnd = nextYear < endDate ? nextYear : endDate;
        const periodDays = (periodEnd - currentStart) / (1000 * 60 * 60 * 24);
        totalDays += periodDays;
  
        let gratuityDays = 0;
        let gratuityDaysPerYear = 0;
        
        if (totalDays < 365) {
          gratuityDays = 0;
          gratuityDaysPerYear = 0;
        } else if (totalDays < 5 * 365) {
          gratuityDays = (periodDays / 365) * 21;
          gratuityDaysPerYear = 21;
        } else {
          // Apply 30 days only after completing 5 years (5 * 365 days)
          const daysInSixthYear = Math.max(totalDays - 5 * 365, 0);
          const priorDays = Math.min(periodDays, daysInSixthYear);
          const priorGratuityDays = ((periodDays - priorDays) / 365) * 21;
          const newGratuityDays = (priorDays / 365) * 30;
        
          gratuityDays = priorGratuityDays + newGratuityDays;
          gratuityDaysPerYear = totalDays > 5 * 365 ? 30 : 21;
        }
  
        const cappedGratuity = Math.min(gratuityDays, gratuityDaysPerYear) * basicSalaryPerDay;
        const periodGratuity = Math.round(cappedGratuity);
  
        gratuityDetails.push({
          periodFrom: currentStart.toISOString().split('T')[0],
          periodTo: periodEnd.toISOString().split('T')[0],
          days: Math.round(periodDays),
          gratuity: Math.round(periodGratuity),
        });
  
        totalGratuity += periodGratuity;
        currentStart = periodEnd;
      }
  
      const years = Math.floor(totalDays / 365);
      const remainingDays = totalDays % 365;
      const months = Math.floor(remainingDays / 30);
      const days = Math.floor(remainingDays % 30);
  
      setTenure(`${years} years, ${months} months, and ${days} days`);
      setGratuity(totalGratuity);
      setDetails(gratuityDetails);
    };
    return (
        <Card>
                    <style>
  {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
</style>
            <CardHeader>
                <h3>Gratuity Calculator</h3>
            </CardHeader>
            <CardBody>
                <Form>
                    <FormGroup>
                        <Label for="firstWorkingDay">First Working Day</Label>
                        <Input
                            type="date"
                            id="firstWorkingDay"
                            value={firstWorkingDay}
                            onChange={(e) => setFirstWorkingDay(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastWorkingDay">Last Working Day</Label>
                        <Input
                            type="date"
                            id="lastWorkingDay"
                            value={lastWorkingDay}
                            onChange={(e) => setLastWorkingDay(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="basicSalary">Basic Salary (AED)</Label>
                        <Input
                            type="number"
                            id="basicSalary"
                            value={basicSalary}
                            onChange={(e) => setBasicSalary(e.target.value)}
                        />
                    </FormGroup>
                    <Button color="primary" onClick={calculateGratuity}>
                        Calculate
                    </Button>
                </Form>

                {gratuity !== null && (
                    <div className="mt-4">
                  
                        <Alert color="success">
                            <strong>Total Gratuity:</strong> AED {gratuity}
                            <br />
                            <strong>Total Tenure:</strong> {tenure}
                        </Alert>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Period From</th>
                                    <th>Period To</th>
                                    <th>Days</th>
                                    <th>Gratuity (AED)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {details.map((detail, index) => (
                                    <tr key={index}>
                                        <td>{detail.periodFrom}</td>
                                        <td>{detail.periodTo}</td>
                                        <td>{detail.days}</td>
                                        <td>{detail.gratuity}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                )}
            </CardBody>
        </Card>
    );
};

export default GratuityCalculator;
