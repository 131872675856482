import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import EmailLogsList from './EmailLogList';
import { Breadcrumbs } from '../../../AbstractElements';


const BasicTables = () => {
    return (
        <Fragment>
            <Breadcrumbs title="Email" mainTitle='' />
            <Container fluid={true}>
                <Row>
                    <EmailLogsList />
                </Row>
            </Container>
        </Fragment>
    );
};

export default BasicTables;