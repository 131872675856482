import React, { Fragment, useState, useEffect } from "react";
import { Col, Form, Row, FormGroup, Label } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

import { groupsService, userService,groupMemberService } from 'Services';
import CommonModal from "Components/Common/Data/Ui-kits/Modals/common/modal";
import { FInput, FGroupSelect, FUserSelect, FMultiUserSelect } from 'CommonElements/Form';
import { Btn } from "AbstractElements";

const GroupAdd = ({ loadData, loadUsers }) => {

  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const { register, handleSubmit, formState: { errors }, reset, control, setValue, getValues } = useForm();

  const [data, setData] = useState({});
  const [rec, setRec] = useState({});
  useEffect(() => {
    loadData(1);
  }, []);
  
  loadData = (page = 1) => {
    setRec({
      loading: true,
      data: [],
    });
    groupsService
      .getGroups({
        page,
      })
      .then((res) => {
        setRec({
          ...res,
          loading: false,
        });
      })
      .catch((err) => {
      });
  };

  const modalSubmit = formValues => {
    if (formValues !== '') {
        setLoading(true);

        groupMemberService.addGroupMember(formValues).then(res => {
            setLoading(false);
            toggle();
            reset();
            toast.success('Group added, successfully');
            loadUsers(formValues.groupId);
        }).catch(err => {
            setLoading(false);
            toast.error('Failed to add Group');
        });
    } else {
        errors.showMessages();
    }
};
const handleChange = (e) => {
    setValue(e.target.name, e.target.files[0]);
}

console.log('res data: ', rec);

  return (
    <Fragment>
      <Btn attrBtn={{ disabled: loading, onClick: toggle, color: 'primary' }}>Add Users</Btn>

      <CommonModal
        isOpen={modal}
        title={'Add New User'}
        toggler={toggle}
        formSubmit={() => { }}
        hideFooter={true}
      >
        <Form className="needs-validation" noValidate=""
          onSubmit={handleSubmit(modalSubmit)}
        >
          <Row>
            <Col md='12 mb-3'>
              <FGroupSelect
                control={control}
                errors={errors}
                label='Select Group'
                name='groupId'
                validation={{ required: true }}
                setValue={setValue}
                value={rec.id}
                defaultOptions={rec.name ? [rec.name] : []}

                // isClient={true}
              />
            </Col>
            <Col md='12 mb-3'>
              <FMultiUserSelect
                control={control}
                errors={errors}
                label='Select User'
                name='userId'
                validation={{ required: true }}
                setValue={setValue}
                value={rec.id}
                defaultOptions={rec.name ? [rec.name] : []}

                // isClient={true}
              />
            </Col>
            
            <Col md={12} className="d-flex flex-row-reverse">
              <Btn disabled={loading} attrBtn={{ color: 'primary' }}>Add</Btn>
            </Col>

          </Row>
        </Form>
      </CommonModal>
    </Fragment>
  );
};

export default GroupAdd;
