import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  Table,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Form,
  FormGroup,
} from 'reactstrap';
import { fetchEmplocation , createEmplocation, updateEmplocation, deleteEmplocation  } from 'Services/emplocation.service';
import { fetchshifts , createshifts , updateshifts , deleteshifts } from 'Services/shifts.service';
import classnames from 'classnames';


const Attendance = () => {
  const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || '1');
  const [shiftsModal, setShiftsModal] = useState(false);
  const [sheetModal, setSheetModal] = useState(false);
  const [locationModal, setlocationModal] = useState(false);
  const [recordsModal, setrecordsModal] = useState(false);
  const [recommendModal, setrecommendModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null);
  const [selectedrecords, setSelectedrecords] = useState(null);
  const [selectedrecommend, setSelectedrecommend] = useState(null);
  const [shiftSearch, setShiftSearch] = useState('');
  const [sheetSearch, setSheetSearch] = useState('');
  const [locationSearch, setlocationSearch] = useState('');
  const [recordsSearch, setrecordsSearch] = useState('');
  const [recommendSearch, setrecommendSearch] = useState('');

  // Toggle between tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const [shifts, setshifts] = useState([]);
  const [Shiftsformvalues, setShiftsformvalues] = useState([
    {

      emp_name: '',
      emp_no: '',
      shift: '',
      hours: '',
      fromDate: '',
      toDate: '',
    },
    // Add more data here
  ]);
  const [isShiftsEditMode, setIsShiftsEditMode] = useState(false);


  const [A_sheetsData, setA_sheetsData] = useState([
    {
      id: 1,
      sheetName: 'attendance_4875_20240913102133972786473.csv',
      fromDate: 'Thu, 2022-09-01',
      toDate: 'Fri, 2022-09-30',
      successes: '8',
      failures: '0',
      status: 'Success',
      importedOn: '2024-09-13 11:21 am'
    },
    // Add more data here
  ]);
  const [locations, setlocation] = useState([]);

  const [locationsFormValues, setlocationsFormValues] = useState({
    emp_name: '',
    emp_no: '',
    geolocation: '',
  });

  const [islocationsEditMode, setIslocationsEditMode] = useState(false);


  const [recordsData, setrecordsData] = useState([
    {
      id: 1,
      emp_name: 'Atul Madaan',
      emp_no: '101009',
      location: 'Dubai Office',
      cardId: '123450',
      shift: 'OPERATIONS SHIFT',
      date: '09-10-2024',
      firstIn: '10:00 AM',
      lastOut: '7:00 PM',
      extraInfo: 'TEST',

    }
  ]);
  const [recommendData, setrecommendsData] = useState([
    {
      id: 1,
      emp_name: 'Atul Madaan',
      emp_no: '101009',
      date: '09-10-2024',
      entrytime: 'Fri, 11:00 AM ',
      exittime: 'Fri, 12:16 AM',
      t_value: '1 hour and 16 minutes',
      t_type: '	Morning Lateness',

    }
  ]);
  //
  // Load Shifts
  const loadshiftsData = async () => {
    try {
      const fetchedshifts = await fetchshifts();
      console.log('Fetched Shifts:', fetchedshifts); // Debugging statement

      if (fetchedshifts && Array.isArray(fetchedshifts.rows)) {
        setshifts(fetchedshifts.rows);
      } else {
        console.error('Expected an array in rows, but got:', fetchedshifts);
      }
    } catch (error) {
      console.error('Error fetching and filtering shifts:', error);
    }
  };
  useEffect(() => {
    loadshiftsData();
  }, []);


  // Load Locations
  const loadlocationsData = async () => {
    try {
      const fetchedlocations = await fetchEmplocation();
      console.log('Fetched Locations:', fetchedlocations); // Debugging statement

      if (fetchedlocations && Array.isArray(fetchedlocations.rows)) {
        setlocation(fetchedlocations.rows);
      } else {
        console.error('Expected an array in rows, but got:', fetchedlocations);
      }
    } catch (error) {
      console.error('Error fetching and filtering locations:', error);
    }
  };

  useEffect(() => {
    loadlocationsData();
  }, []);


  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);


  // Toggle modal for shifts
  const toggleShiftModal = () => setShiftsModal(!shiftsModal);
  // Toggle modal for sheets
  const toggleSheetModal = (sheet = null) => {
    setSelectedSheet(sheet);
    setSheetModal(!sheetModal);
  };

  //Toggle modal for location
  const togglelocationModal = () => setlocationModal(!locationModal); // Add this line
  //Toggle modal for records
  const togglerecordsModal = (records = null) => {
    setSelectedrecords(records);
    setrecordsModal(!recordsModal);
  };
  //Toggle modal for reccommend
  const togglerecommendModal = (recommend = null) => {
    setSelectedrecommend(recommend);
    setrecommendModal(!recommendModal);
  };


  // Shifts Info Handlers
  // Handles form input changes for shifts
  const handleShiftsFormChange = (e, id) => {
    const { name, value } = e.target;

    // Update shift form values and handle setting predetermined hours
    setShiftsformvalues((prevValues) =>
      prevValues.map((shift, index) =>
        index === id
          ? {
            ...shift,
            [name]: value,
            hours:
              name === 'shift'
                ? value === 'OPERATIONS SHIFT'
                  ? '10:00AM TO 7:00PM' // Predetermined hours for OPERATIONS SHIFT
                  : value === 'MORNING SHIFT'
                    ? '8:00AM TO 5:00PM' // Predetermined hours for MORNING SHIFT
                    : '' // Reset hours if no shift selected or other shifts
                : shift.hours,
          }
          : shift
      )
    );
  };


  // Initializes the form for creating a new shift
  const initializeCreateShiftForm = () => {
    setIsShiftsEditMode(false);
    setShiftsformvalues([
      {
        emp_name: '',
        emp_no: '',
        shift: '',
        hours: '',
        fromDate: '',
        toDate: '',
      },
    ]); // Reset to default values
    toggleShiftModal(true); // Opens the modal
  };

  // Initializes the form for updating an existing shift
  const initializeUpdateShiftForm = (shift) => {
    // Ensure shift is wrapped correctly based on how your form expects data
    setShiftsformvalues([shift]); // If your form expects an array with one shift
    setIsShiftsEditMode(true);
    toggleShiftModal(); // Open the modal
  };

  // Deletes a user shift by ID
  const deleteShift = async (id) => {
    try {
      await deleteshifts(id); // Assuming this is the API call to delete the shift
      toast.success('Shift deleted successfully!');
      setshifts((prevDetails) => prevDetails.filter(shift => shift.id !== id));
    } catch (error) {
      console.error('Error deleting shift:', error);
      toast.error('Failed to delete shift. Please try again.');
    }
  };

  // Handles saving the shift (create or update)
  const handleSaveShift = async (e) => {
    e.preventDefault();

    // Validate required fields
    const isValid = Shiftsformvalues.every((shift) =>
      shift.emp_name && shift.shift && shift.hours && shift.fromDate && shift.toDate
    );

    if (!isValid) {
      toast.error('Please fill in all required fields.');
      return;
    }

    try {
      if (isShiftsEditMode) {
        // Call the actual update API function for the specific shift
        await updateshifts(Shiftsformvalues[0].id, Shiftsformvalues[0]); // Assuming you're editing the first shift
        toast.success('Shift updated successfully!');
      } else {
        // Call the actual create API function
        await createshifts(Shiftsformvalues[0]); // Assuming you want to add the first shift
        toast.success('Shift added successfully!');
      }

      // Refresh the shifts list after saving
      await loadshiftsData();

      // Close the shifts modal after saving
      toggleShiftModal();
    } catch (error) {
      console.error('Error saving shift:', error);
      toast.error('Failed to save shift. Please try again.');
    }
  };

  // Handles searching for shifts
  const handleSearchShiftChange = (e) => {
    setShiftSearch(e.target.value); // Update search query
  };

  // Location Info Handlers
  // Handles form input changes
  const handleLocationFormChange = (e) => {
    const { name, value } = e.target;
    setlocationsFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Initializes the form for creating a new location
  const initializeCreateLocationForm = () => {
    setIslocationsEditMode(false);
    setlocationsFormValues({
      emp_name: '',
      emp_no: '',
      geolocation: ''
    });
    togglelocationModal(true); // Opens the modal
  };

  // Initializes the form for updating an existing location
  const initializeUpdateLocationForm = (location) => {
    setIslocationsEditMode(true);
    setlocationsFormValues(location);
    togglelocationModal(true); // Opens the modal
  };

  // Deletes a user location by ID
  const deleteLocation = async (id) => {
    try {
      await deleteEmplocation(id); // Assuming this is the API call to delete the location
      toast.success('Location deleted successfully!');
      setlocation(prevDetails => prevDetails.filter(location => location.id !== id));
    } catch (error) {
      console.error('Error deleting location:', error);
      toast.error('Failed to delete location. Please try again.');
    }
  };

  // Handles saving the location (create or update)
  const handleSaveLocation = async (e) => {
    e.preventDefault();

    // Validate required fields
    if (!locationsFormValues.emp_name || !locationsFormValues.geolocation) {
      toast.error('Please fill in all required fields.');
      return;
    }

    const payload = {
      ...locationsFormValues,
      emp_no: locationsFormValues.emp_no
    };

    try {
      if (islocationsEditMode) {
        // Call the actual update API function
        await updateEmplocation(locationsFormValues.id, payload);
        toast.success('Location updated successfully!');
      } else {
        // Call the actual create API function
        await createEmplocation(payload);
        toast.success('Location added successfully!');
      }

      // Refresh the locations list after saving
      await loadlocationsData();

      // Close the location modal after saving
      togglelocationModal();
    } catch (error) {
      console.error('Error saving location:', error);
      toast.error('Failed to save location. Please try again.');
    }
  };
  const handleSearchlocationChange = (e) => {
    setlocationSearch(e.target.value); // Update search query
  };



  const filteredshifts = shifts.filter((shift) =>
    shift.emp_name.toLowerCase().includes(shiftSearch.toLowerCase()) ||
    shift.emp_no.toLowerCase().includes(shiftSearch.toLowerCase()) ||
    shift.shift.toLowerCase().includes(shiftSearch.toLowerCase())
  );
  const filteredlocations = locations.filter((location) =>
    location.emp_name.toLowerCase().includes(locationSearch.toLowerCase()) ||
    location.emp_no.toLowerCase().includes(locationSearch.toLowerCase()) ||
    location.geolocation.toLowerCase().includes(locationSearch.toLowerCase())
  );
    // Filter sheets data based on search query
    const filteredSheets = A_sheetsData.filter((sheet) =>
      sheet.sheetName.toLowerCase().includes(sheetSearch.toLowerCase()) ||
      sheet.status.toLowerCase().includes(sheetSearch.toLowerCase())
    );
  
    // Filter records data based on search query
    const filteredRecords = recordsData.filter((record) =>
      record.emp_name.toLowerCase().includes(recordsSearch.toLowerCase()) ||
      record.emp_no.toLowerCase().includes(recordsSearch.toLowerCase()) ||
      record.location.toLowerCase().includes(recordsSearch.toLowerCase()) ||
      record.shift.toLowerCase().includes(recordsSearch.toLowerCase()) ||
      record.date.toLowerCase().includes(recordsSearch.toLowerCase()) ||
      record.firstIn.toLowerCase().includes(recordsSearch.toLowerCase()) ||
      record.lastOut.toLowerCase().includes(recordsSearch.toLowerCase()) ||
      record.extraInfo.toLowerCase().includes(recordsSearch.toLowerCase())
    );
  
    // Filter recommendations data based on search query
    const filteredRecommendations = recommendData.filter((recommend) =>
      recommend.emp_name.toLowerCase().includes(recommendSearch.toLowerCase()) ||
      recommend.emp_no.toLowerCase().includes(recommendSearch.toLowerCase()) ||
      recommend.date.toLowerCase().includes(recommendSearch.toLowerCase()) ||
      recommend.entrytime.toLowerCase().includes(recommendSearch.toLowerCase()) ||
      recommend.exittime.toLowerCase().includes(recommendSearch.toLowerCase()) ||
      recommend.t_value.toLowerCase().includes(recommendSearch.toLowerCase()) ||
      recommend.t_type.toLowerCase().includes(recommendSearch.toLowerCase())
    );

  // Render shift modal
  const renderShiftModal = () => {
    return (
      <Modal isOpen={shiftsModal} toggle={toggleShiftModal}>
        <ModalHeader toggle={toggleShiftModal}>
          {isShiftsEditMode ? 'Edit Shift Info' : 'Add Shift Info'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSaveShift}>
            {Shiftsformvalues.map((shift, id) => (
              <div key={id}> {/* Add unique key */}
                <FormGroup>
                  <Label for={`emp_name_${id}`}>Employee Name</Label>
                  <Input
                    type="text"
                    name="emp_name"
                    id={`emp_name_${id}`} // Add unique id
                    value={shift.emp_name || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`emp_no_${id}`}>Employee Number</Label>
                  <Input
                    type="text"
                    name="emp_no"
                    id={`emp_no_${id}`} // Add unique id
                    value={shift.emp_no || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`shift_${id}`}>Shift</Label>
                  <Input
                    type="select"
                    name="shift"
                    id={`shift_${id}`} // Add unique id
                    value={shift.shift || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  >
                    <option value="">Select Shift</option> {/* Default placeholder option */}
                    <option value="OPERATIONS SHIFT">OPERATIONS SHIFT</option>
                    <option value="MORNING SHIFT">MORNING SHIFT</option>
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Label for={`hours_${id}`}>Hours</Label>
                  <Input
                    type="text"
                    name="hours"
                    id={`hours_${id}`} // Add unique id
                    value={shift.hours || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`fromDate_${id}`}>From Date</Label>
                  <Input
                    type="date"
                    name="fromDate"
                    id={`fromDate_${id}`} // Add unique id
                    value={shift.fromDate || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label for={`toDate_${id}`}>To Date</Label>
                  <Input
                    type="date"
                    name="toDate"
                    id={`toDate_${id}`} // Add unique id
                    value={shift.toDate || ''}
                    onChange={(e) => handleShiftsFormChange(e, id)} // Pass id
                    required
                  />
                </FormGroup>
              </div>
            ))}

            <ModalFooter>
              <Button color="primary" type="submit">
                {isShiftsEditMode ? 'Update' : 'Save'}
              </Button>
              <Button color="secondary" onClick={toggleShiftModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    );
  };

  // Render sheet modal
  const renderSheetModal = () => {
    if (!selectedSheet) return null;

    return (
      <Modal isOpen={sheetModal} toggle={() => toggleSheetModal()}>
        <ModalHeader toggle={() => toggleSheetModal()}>Sheet Details</ModalHeader>
        <ModalBody>
          <Label>Sheet Name: </Label>
          <p>{selectedSheet.sheetName}</p>

          <Label>From Date: </Label>
          <p>{selectedSheet.fromDate}</p>

          <Label>To Date: </Label>
          <p>{selectedSheet.toDate}</p>

          <Label>Successes: </Label>
          <p>{selectedSheet.successes}</p>

          <Label>Failures: </Label>
          <p>{selectedSheet.failures}</p>

          <Label>Status: </Label>
          <p>{selectedSheet.status}</p>

          <Label>Imported On: </Label>
          <p>{selectedSheet.importedOn}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggleSheetModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const handleRowSelect = (id) => {
    const newSelectedRows = [...selectedRows];
    if (newSelectedRows.includes(id)) {
      newSelectedRows.splice(newSelectedRows.idOf(id), 1);
    } else {
      newSelectedRows.push(id);
    }
    setSelectedRows(newSelectedRows);
  };
  // Render Location modal
  const renderlocationModal = () => {

    return (
      <Modal isOpen={locationModal} toggle={togglelocationModal}>
        <ModalHeader toggle={togglelocationModal}>
          {islocationsEditMode ? 'Edit Location Info' : 'Add Location Info'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSaveLocation}>
            <FormGroup>
              <Label for="emp_name">Employee Name</Label>
              <Input
                type="text"
                name="emp_name"
                id="emp_name"
                value={locationsFormValues.emp_name || ''}
                onChange={handleLocationFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="emp_no">Employee Number</Label>
              <Input
                type="text"
                name="emp_no"
                id="emp_no"
                value={locationsFormValues.emp_no || ''}
                onChange={handleLocationFormChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label for="geolocation">Geolocation</Label>
              <Input
                type="select"
                name="geolocation"
                id="geolocation"
                value={locationsFormValues.geolocation}
                onChange={handleLocationFormChange}
                required
              >
                <option value="">Select Location</option>
                <option value="Location 1">Location 1</option>
                <option value="Location 2">Location 2</option>
                <option value="Location 3">Location 3</option>
                <option value="Location 4">Location 4</option>
                <option value="Location 5">Location 5</option>
              </Input>
            </FormGroup>

            <ModalFooter>
              <Button color="primary" type="submit">
                {islocationsEditMode ? 'Update' : 'Save'}
              </Button>
              <Button color="secondary" onClick={togglelocationModal}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>

    );
  };
  // Render records modal
  const renderRecordsModal = () => {
    if (!selectedrecords) return null;

    return (
      <Modal isOpen={recordsModal} toggle={() => togglerecordsModal()}>
        <ModalHeader toggle={() => togglerecordsModal()}>Attendance Records</ModalHeader>
        <ModalBody>
          <Label>Employee Name: </Label>
          <p>{selectedrecords.emp_name}</p>

          <Label>Employee Number: </Label>
          <p>{selectedrecords.emp_no}</p>

          <Label>Attendance Card ID: </Label>
          <p>{selectedrecords.cardId}</p>

          <Label>Shifts: </Label>
          <p>{selectedrecords.shift}</p>

          <Label>Attendance Date: </Label>
          <p>{selectedrecords.date}</p>

          <Label>First In: </Label>
          <p>{selectedrecords.firstIn}</p>

          <Label>Last Out: </Label>
          <p>{selectedrecords.lastOut}</p>

          <Label>Extra Info: </Label>
          <p>{selectedrecords.extraInfo}</p>

        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => togglerecordsModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };
  // Render recommend modal
  const renderRecommendModal = () => {
    if (!selectedrecommend) return null;

    return (
      <Modal isOpen={recommendModal} toggle={() => togglerecommendModal()}>
        <ModalHeader toggle={() => togglerecommendModal()}>Recommendations</ModalHeader>
        <ModalBody>
          <Label>Employee Name: </Label>
          <p>{selectedrecommend.emp_name}</p>

          <Label>Employee Number: </Label>
          <p>{selectedrecommend.emp_no}</p>

          <Label>Attendance Date: </Label>
          <p>{selectedrecommend.date}</p>

          <Label>Entry Time: </Label>
          <p>{selectedrecommend.entrytime}</p>

          <Label>Exit Time: </Label>
          <p>{selectedrecommend.exittime}</p>

          <Label>Transaction Value: </Label>
          <p>{selectedrecommend.t_value}</p>

          <Label>Transaction Type: </Label>
          <p>{selectedrecommend.t_type}</p>



        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => togglerecommendModal()}>
            OK
          </Button>
        </ModalFooter>
      </Modal>
    );
  };




  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: '1' })}
            onClick={() => toggleTab('1')}
            style={{
              cursor: 'pointer',
              color: activeTab === '1' ? '#194995' : 'black',
            }}
          >
            Shifts
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => toggleTab('2')}
            style={{
              cursor: 'pointer',
              color: activeTab === '2' ? '#194995' : 'black',
            }}
          >
            Attendance Sheets
          </NavLink>
        </NavItem> */}
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => toggleTab('3')}
            style={{
              cursor: 'pointer',
              color: activeTab === '3' ? '#194995' : 'black',
            }}
          >
            Employee GeoLocation
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '4' })}
            onClick={() => toggleTab('4')}
            style={{
              cursor: 'pointer',
              color: activeTab === '4' ? '#194995' : 'black',
            }}
          >
            Manage Attendence Records
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '5' })}
            onClick={() => toggleTab('5')}
            style={{
              cursor: 'pointer',
              color: activeTab === '5' ? '#194995' : 'black',
            }}
          >
            Recommendations
          </NavLink>
        </NavItem> */}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                Employee Shifts
              </h3>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                  type="text"
                  placeholder="Search Shifts"
                  value={shiftSearch}
                  onChange={handleSearchShiftChange}
                  style={{ width: '300px', marginBottom: '20px' }}
                />
                <Button onClick={() => initializeCreateShiftForm({})} style={{ marginLeft: '10px' }}>
                  Add
                </Button>
              </div>
            </CardHeader>
            <style>
  {`
    .form-group.w-75 {
      display: none;
 
    }
      .form-inline.search-fulls.col-7 {
        border: none;
      }

    .MuiBox-root.css-19midj6 {
            padding: 3px;
      }
  `}
</style>

            <CardBody className="scrollable-table-container">
              <Table bordered hover className="scrollable-table">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee Number</th>
                    <th>Shift</th>
                    <th>Hours</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredshifts.map((shift) => (
                    <tr key={shift.id}>
                      <td>{shift.emp_name}</td>
                      <td>{shift.emp_no}</td>
                      <td>{shift.shift}</td>
                      <td>{shift.hours}</td>
                      <td>{shift.fromDate}</td>
                      <td>{shift.toDate}</td>
                      <td>
                        <Button color="primary" onClick={() => initializeUpdateShiftForm(shift)}>
                          Edit
                        </Button>
                      </td>
                      <td>
                        <Button color="danger" onClick={() => deleteShift(shift.id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="2">
          <Card>
            <CardHeader>
              <h3>Attendance Sheets</h3>
              <Input
                type="text"
                placeholder="Search Attendence Sheets"
                value={sheetSearch}
                onChange={(e) => setSheetSearch(e.target.value)}
              />

            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Sheet Name</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Successes</th>
                    <th>Failures</th>
                    <th>Status</th>
                    <th>Imported On</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredSheets.map((sheet) => (
                    <tr key={sheet.id}>
                      <td>
                        <Input
                          type="checkbox"
                          checked={selectedRows.includes(sheet.id)}
                          onChange={() => handleRowSelect(sheet.id)}
                        />

                      </td>
                      <td>{sheet.sheetName}</td>
                      <td>{sheet.fromDate}</td>
                      <td>{sheet.toDate}</td>
                      <td>{sheet.successes}</td>
                      <td>{sheet.failures}</td>
                      <td>{sheet.status}</td>
                      <td>{sheet.importedOn}</td>
                      <td>
                        <Button color="primary" onClick={() => toggleSheetModal(sheet)}>
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="3">
          <Card>
            <CardHeader>
              <h3 style={{ fontSize: '24px', fontWeight: 'bold', color: '#194995', marginBottom: '20px' }}>
                Employee Geolocation
              </h3>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Input
                  type="text"
                  placeholder="Search  Employee Geolocation"
                  value={locationSearch}
                  onChange={handleSearchlocationChange}
                  style={{ width: '300px', marginBottom: '20px' }}
                />
                <Button onClick={() => initializeCreateLocationForm({})} style={{ marginLeft: '10px' }}>
                  Add
                </Button>
              </div>
            </CardHeader>
            <CardBody className="scrollable-table-container">
              <Table bordered hover className="scrollable-table">
                <thead>
                  <tr>
                    <th>Employee Name</th>
                    <th>Employee Number</th>
                    <th>Geolocation</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredlocations.map((location) => (
                    <tr key={location.id}>
                      <td>{location.emp_name}</td>
                      <td>{location.emp_no}</td>
                      <td>{location.geolocation}</td>
                      <td>
                        <Button color="primary" onClick={() => initializeUpdateLocationForm(location)}>
                          Edit
                        </Button>
                      </td>
                      <td>
                        <Button color="danger" onClick={() => deleteLocation(location.id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <p>Showing {locations.length} results</p>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="4">
          <Card>
            <CardHeader>
              <h3>Manage Attendence Records</h3>
              <Input
                type="text"
                placeholder="Search Manage Attendence Records "
                value={recordsSearch}
                onChange={(e) => setrecordsSearch(e.target.value)}
              />
            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Emp. Name</th>
                    <th>Emp. No.</th>
                    <th>Attendance Card ID</th>
                    <th>Shift</th>
                    <th>Attendance Date</th>
                    <th>Frist In</th>
                    <th>Last Out</th>
                    <th>Extra Info</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecords.map((record) => (
                    <tr key={record.id}>
                      <td>{record.emp_name}</td>
                      <td>{record.emp_no}</td>
                      <td>{record.cardId}</td>
                      <td>{record.shift}</td>
                      <td>{record.date}</td>
                      <td>{record.firstIn}</td>
                      <td>{record.lastOut}</td>
                      <td>{record.extraInfo}</td>
                      <td>
                        <Button color="primary" onClick={() => togglerecordsModal(record)}>
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
        <TabPane tabId="5">
          <Card>
            <CardHeader>
              <h3>Recommendations</h3>
              <Input
                type="text"
                placeholder="Search Recommendations"
                value={recommendSearch}
                onChange={(e) => setrecommendSearch(e.target.value)}
              />

            </CardHeader>
            <CardBody>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Emp. Name</th>
                    <th>Emp. No.</th>
                    <th>Attendance Date</th>
                    <th>Entry Time</th>
                    <th>Exit Time</th>
                    <th>Transaction Value</th>
                    <th>Transaction Type</th>
                    <th>Edit</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecommendations.map((recommend) => (
                    <tr key={recommend.id}>
                      <td>{recommend.emp_name}</td>
                      <td>{recommend.emp_no}</td>
                      <td>{recommend.date}</td>
                      <td>{recommend.entrytime}</td>
                      <td>{recommend.exittime}</td>
                      <td>{recommend.t_value}</td>
                      <td>{recommend.t_type}</td>
                      <td>
                        <Button color="primary" onClick={() => togglerecommendModal(recommend)}>
                          Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </TabPane>
      </TabContent>

      {renderShiftModal()}
      {renderSheetModal()}
      {renderlocationModal()}
      {renderRecordsModal()}
      {renderRecommendModal()}
    </>
  );
};

export default Attendance;